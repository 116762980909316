import React, { useLayoutEffect, useState } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/CustomForms.scss";
import { Col, Row, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AccessDenied from "../../../AccessDenied";
import CreatedFormNames from "./CreatedFormNames";
import CreateNewForm from "./CreateNewForm";
import Footer from "../../Footer";

export default function CreateFormfromAPI() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [gotoCreateForm, setGotoCreateForm] = useState(true);
  const [customPageId, setCustomPageId] = useState(0);

  const [formNameCreated, setFormNameCreated] = useState("");
  const [addEditField, setAddEditField] = useState(true);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // Page navigation

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              <div className={`main-body ${toggleshift.style}`}>
                {/* PAGE CONTENT START */}
                <div className="page-content">
                  <div className="custom-forms-from-api">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6 className="page-header-text">CUSTOM FORMS</h6>
                        </div>
                      </Col>
                      <Col md={5} className="cat-col"></Col>
                      <Col md={4} className="cat-col"></Col>
                    </Row>
                    <div>
                      {gotoCreateForm ? (
                        <CreatedFormNames
                          setGotoCreateForm={setGotoCreateForm}
                          setFormNameCreated={setFormNameCreated}
                          setAddEditField={setAddEditField}
                          setCustomPageId={setCustomPageId}
                        />
                      ) : (
                        <CreateNewForm
                          formNameCreated={formNameCreated}
                          customPageId={customPageId}
                          setGotoCreateForm={setGotoCreateForm}
                          addEditField={addEditField}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* PAGE CONTENT END*/}
              </div>
              {/* MAIN BODY END */}
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
      <Footer />
    </div>
  );
}
