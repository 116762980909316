import React, { useEffect, useState } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { loggedInRole, loggedInId } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import Footer from "../Footer";
import TravelReportTable from "./TravelReportTable";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSingleTravelData,
  loadTravelReportAttachments,
  loadManagerDropdown,
  loadUserDropdownByManager,
} from "../../redux/action";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import moment from "moment";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { exportExcel } from "../DownloadFile";
import { baseURL } from "../BaseUrl";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function TravelReport() {
  let tableHeader = [
    { name: "DATE", align: "left", width: "120px" },
    { name: "EMPLOYEE NAME", align: "left", width: "150px" },
    { name: "FROM PLACE", align: "left", width: "250px" },
    { name: "TO PLACE", align: "left", width: "250px" },
    { name: "DEALER CODE", align: "left", width: "100px" },
    { name: "DEALER", align: "left", width: "180px" },
    { name: "VISIT TYPE", align: "left", width: "120px" },
    { name: "VISIT TIME", align: "left", width: "80px" },
    { name: "MODE OF TRAVEL", align: "left", width: "100px" },
    { name: "KM", align: "left", width: "100px" },
    { name: "AMOUNT", align: "left", width: "120px" },
    { name: "DA (FOOD)", align: "left", width: "120px" },
    { name: "STAY", align: "left", width: "120px" },
    { name: "OTHERS", align: "left", width: "120px" },
    { name: "TOTAL", align: "left", width: "120px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let dispatch = useDispatch();
  const { managerDropdown } = useSelector((state) => state.data);
  const { userDropdownByManager } = useSelector((state) => state.data);
  const { getTravelAttachments } = useSelector((state) => state.data);
  const { getSingleTravelReportData } = useSelector((state) => state.data);

  // PAGINATION START
  const [filterArray, setFilterArray] = useState([]);
  const [PageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [userId, setUserId] = useState({
    value: "",
    label: "",
  });
  const [managerId, setManagerId] = useState({
    value: loggedInId,
    label: "Select Manager",
  });

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [clientId, setClientId] = useState("");

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    let list = [];
    if (fromDate && toDate) {
      list.push({
        key: "FromDate",
        value: moment(new Date(fromDate)).format("DD/MM/YYYY"),
      });
      list.push({
        key: "ToDate",
        value: moment(new Date(toDate)).format("DD/MM/YYYY"),
      });
    }
    setFilterArray(list);
  }, [fromDate, toDate]);

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "user") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => {
          return item.key !== "UserID";
        });
      }
      list.push({ key: "UserID", value: data.value });
      setUserId(data);
    } else if (type === "manager") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => {
          return item.key !== "ManagerId";
        });
      }
      list.push({ key: "ManagerId", value: data.value });
      setManagerId(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => {
            return item.key !== "FromDate";
          });
        }
        if (end) {
          list = filterArray.filter((item) => {
            return item.key !== "ToDate";
          });
        }
      }
      if (start && end === null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "toDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadManagerDropdown());
    dispatch(loadUserDropdownByManager(managerId.value));
  }, [managerId]);

  useEffect(() => {
    fetchTravelReportData(filterArray, currentPage, PageSize);
  }, [filterArray, currentPage, PageSize]);

  const fetchTravelReportData = () => {
    dispatch(
      loadTravelReportAttachments(
        filterArray,
        currentPage,
        PageSize,
        setIsLoading,
        setLoading
      )
    );
  };

  const handleIconClick = (clientid) => {
    setClientId(clientid);
    navigate(`/travelReportAttachment/${clientid}`);
    fetchSingleData(clientid);
  };

  const handleDownloadIconClick = (clientid) => {
    setClientId(clientid);
    navigate(`/travelReportTableData/${clientid}`);
    fetchSingleData(clientid);
  };

  const fetchSingleData = (clientid) => {
    dispatch(
      loadSingleTravelData(clientid, filterArray, currentPage, PageSize)
    );
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  const clearSerachBySelect = () => {
    setUserId({ value: "", label: "" });
    setManagerId({
      value: loggedInId,
      label: "Select Manager",
    });
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray("");
  };

  const exportReferenceInfoDetails = () => {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        param = param + "&" + item.key + "=" + item.value;
      });
    }
    exportExcel(
      `${baseURL}/api/export/TravelReportExcelExport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      "TravelReport.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole === 1 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">TRAVEL REPORT</h6>
                      </div>
                    </Col>

                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div
                          className="header-filter-btn"
                          onClick={() => setOpenFilterTab(!openFilterTab)}
                        >
                          <div className="filter-icon-text">
                            <i className="bi bi-sliders" />
                            <span>Filters</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={managerDropdown}
                            placeholder="Select Manager"
                            value={managerId.value && managerId}
                            onInputChange={managerDropdown.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "manager")
                            }
                            isSearchable={true}
                          />
                        </div>
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userDropdownByManager}
                            placeholder="Select User"
                            value={userId.value && userId}
                            onInputChange={userDropdownByManager.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "user")
                            }
                            isSearchable={true}
                          />
                        </div>

                        <div className="from-to-date-select">
                          <div className="input-group-text" id="from-label">
                            From - To
                          </div>
                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date date-filter"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "date")
                              }
                              maxDate={new Date()}
                            />
                            <div>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "6px" }}
                              />
                            </div>
                          </div>
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}

                <TravelReportTable
                  tableHeader={tableHeader}
                  getTravelAttachments={getTravelAttachments}
                  isLoading={isLoading}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                  handleIconClick={handleIconClick}
                  handleDownloadIconClick={handleDownloadIconClick}
                  clientId={clientId}
                  setClientId={setClientId}
                />

                {/*TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn
                    btnName="Download"
                    onClickEffect={exportReferenceInfoDetails}
                  />
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default TravelReport;
