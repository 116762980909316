import React, { useState, useEffect } from "react";
import "../../styles/Claims.scss";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import * as swal from "../Consturl/SwalAlert";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import { SbApproveBtn, SbDownloadBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { travelizeAuth } from "../HeaderAuthentication";
import DownloadLoading from "../ReUseComponents/DownloadLoading";

function PendingClaimByAdmin(props) {
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [pendingForApprovalData, setPendingForApprovalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [localClaimAlertMsg, setLocalClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [outStationClaimAlertMsg, setOutStationClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [foodClaimAlertMsg, setFoodClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [hotelClaimAlertMsg, setHotelClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [otherClaimAlertMsg, setOtherClaimAlertMsg] = useState({
    claimId: "",
    message: "",
  });
  const [MeetingID, setMeetingID] = useState("");
  const [totalbySection, setTotalbySection] = useState({
    localTotal: 0,
    totalHotel: 0,
    totalFood: 0,
    totalOutstation: 0,
    totalOthers: 0,
  });

  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS START
  useEffect(() => {
    {
      fromDate && toDate && fetchManagerPendingApproval();
    }
  }, [fromDate, toDate, PageSize, currentPage]);

  const fetchManagerPendingApproval = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/OSClaim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusID=42&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("CLAIM-PENDING-APPROVAL::", res.result);
        if (res.success) {
          setIsLoading(false);
          res.result.forEach((element) => {
            element.inputState = {
              chkSelectClaim: false,
              approvedFareClaimAmount: 0,
              fareClaimRemark: "",
              approvedLocalConveyanceClaimAmount: 0,
              localConveyanceClaimRemark: "",
              approvedFoodClaimAmount: 0,
              foodClaimRemark: "",
              approvedHotelClaimAmount: 0,
              hotelClaimRemark: "",
              approvedOthersClaimAmount: 0,
              othersClaimRemark: "",
              approvedTotalClaimAmount: 0,
              rejectedTotalClaimAmount: 0,
              totalAppliedClaimAmmount: element.totalAmount,
              remark: "",
              date: "",
              approvalType: 1, //Admin Prooval
              claimID: element.claimID,
            };
          });

          const localTotal = res.result.map((data) => data.localClaim.amount);
          const hotelTotal = res.result.map((data) => data.hotelClaim.amount);
          const foodTotal = res.result.map((data) => data.foodClaim.amount);
          const outstationTotal = res.result.map(
            (data) => data.fareClaim.amount
          );
          const othersTotal = res.result.map((data) => data.othersClaim.amount);
          // let sumLocal = localTotal.reduce((a, b) => a + b, 0);
          setTotalbySection({
            localTotal: localTotal.reduce((a, b) => a + b, 0),
            totalHotel: hotelTotal.reduce((a, b) => a + b, 0),
            totalFood: foodTotal.reduce((a, b) => a + b, 0),
            totalOutstation: outstationTotal.reduce((a, b) => a + b, 0),
            totalOthers: othersTotal.reduce((a, b) => a + b, 0),
            grandTotal: 0,
          });
          setPendingForApprovalData(res?.result);
          setPageCount(Math.ceil(res?.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setPendingForApprovalData("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };
  // GET MANAGER PENDING FOR APPROVAL CLAIM DETAILS END
  const [selectAllStatus, setSelectAllStatus] = useState(null);

  const setClaimNumber = (state, claimID) => {
    let allSelect = [];
    if (claimID != 0) {
      let claim = pendingForApprovalData.filter((x) => x.claimID == claimID);
      claim[0].inputState.chkSelectClaim = state;
      calclulateDefaultAmount(claim[0]);
    } else {
      pendingForApprovalData.forEach((claim) => {
        claim.inputState.chkSelectClaim = state;
        calclulateDefaultAmount(claim);
      });
    }
    allSelect.push(
      pendingForApprovalData.map((data) => data.inputState.chkSelectClaim)
    );
    setSelectAllStatus(allSelect.flat().every((element) => element == true));
    setPendingForApprovalData([...pendingForApprovalData]);
  };

  const calclulateDefaultAmount = (claim) => {
    if (claim.inputState.chkSelectClaim) {
      claim.inputState.approvedFareClaimAmount = claim.fareClaim.amount;
      claim.inputState.approvedLocalConveyanceClaimAmount =
        claim.localClaim.amount;
      claim.inputState.approvedFoodClaimAmount = claim.foodClaim.amount;
      claim.inputState.approvedHotelClaimAmount = claim.hotelClaim.amount;
      claim.inputState.approvedOthersClaimAmount = claim.othersClaim.amount;
      claim.inputState.approvedTotalClaimAmount = claim.totalAmount;
      claim.inputState.rejectedTotalClaimAmount = 0;
    } else {
      claim.inputState.approvedFareClaimAmount = 0;
      claim.inputState.approvedLocalConveyanceClaimAmount = 0;
      claim.inputState.approvedFoodClaimAmount = 0;
      claim.inputState.approvedHotelClaimAmount = 0;
      claim.inputState.approvedOthersClaimAmount = 0;
      claim.inputState.approvedTotalClaimAmount = 0;
      claim.inputState.rejectedTotalClaimAmount = 0;
    }
  };

  const claimCalculation = (claim) => {
    claim.inputState.approvedTotalClaimAmount =
      claim.inputState.approvedFareClaimAmount +
      claim.inputState.approvedLocalConveyanceClaimAmount +
      claim.inputState.approvedFoodClaimAmount +
      claim.inputState.approvedHotelClaimAmount +
      claim.inputState.approvedOthersClaimAmount;
    claim.inputState.rejectedTotalClaimAmount =
      claim.totalAmount - claim.inputState.approvedTotalClaimAmount;
  };

  // input handlechange
  const handleInput = (value, name, claimid) => {
    let claim = pendingForApprovalData.filter(
      (data) => data.claimID == claimid
    );
    claim[0].inputState[name] = value;
    claimCalculation(claim[0]);
    setPendingForApprovalData([...pendingForApprovalData]);
  };
  // input handlechange

  const approveClaimAmount = (e) => {
    let singleClaimData = pendingForApprovalData.filter(
      (data) => data.inputState.chkSelectClaim == true
    );

    if (singleClaimData.length == 1) {
      if (
        singleClaimData[0].inputState.approvedFareClaimAmount <=
          singleClaimData[0].fareClaim.amount &&
        singleClaimData[0].inputState.approvedLocalConveyanceClaimAmount <=
          singleClaimData[0].localClaim.amount &&
        singleClaimData[0].inputState.approvedFoodClaimAmount <=
          singleClaimData[0].foodClaim.amount &&
        singleClaimData[0].inputState.approvedHotelClaimAmount <=
          singleClaimData[0].hotelClaim.amount &&
        singleClaimData[0].inputState.approvedOthersClaimAmount <=
          singleClaimData[0].othersClaim.amount
      ) {
        if (
          (singleClaimData[0].inputState.approvedFareClaimAmount <
          singleClaimData[0].fareClaim.amount
            ? singleClaimData[0].inputState.fareClaimRemark != ""
            : singleClaimData[0].inputState.fareClaimRemark == "" ||
              singleClaimData[0].inputState.fareClaimRemark != "") &&
          (singleClaimData[0].inputState.approvedLocalConveyanceClaimAmount <
          singleClaimData[0].localClaim.amount
            ? singleClaimData[0].inputState.localConveyanceClaimRemark != ""
            : singleClaimData[0].inputState.localConveyanceClaimRemark == "" ||
              singleClaimData[0].inputState.localConveyanceClaimRemark != "") &&
          (singleClaimData[0].inputState.approvedFoodClaimAmount <
          singleClaimData[0].foodClaim.amount
            ? singleClaimData[0].inputState.foodClaimRemark != ""
            : singleClaimData[0].inputState.foodClaimRemark == "" ||
              singleClaimData[0].inputState.foodClaimRemark != "") &&
          (singleClaimData[0].inputState.approvedHotelClaimAmount <
          singleClaimData[0].hotelClaim.amount
            ? singleClaimData[0].inputState.hotelClaimRemark != ""
            : singleClaimData[0].inputState.hotelClaimRemark == "" ||
              singleClaimData[0].inputState.hotelClaimRemark != "") &&
          (singleClaimData[0].inputState.approvedOthersClaimAmount <
          singleClaimData[0].othersClaim.amount
            ? singleClaimData[0].inputState.othersClaimRemark != ""
            : singleClaimData[0].inputState.othersClaimRemark == "" ||
              singleClaimData[0].inputState.othersClaimRemark != "")
        ) {
          let claimApprovedData = {
            date: new Date(),
            approvedFareClaimAmount:
              singleClaimData[0].inputState.approvedFareClaimAmount,
            approvedFareClaimRemark:
              singleClaimData[0].inputState.fareClaimRemark,
            approvedLocalClaimAmount:
              singleClaimData[0].inputState.approvedLocalConveyanceClaimAmount,
            approvedLocalClaimRemark:
              singleClaimData[0].inputState.localConveyanceClaimRemark,
            // OS Claim
            approvedOSClaimAmount: 0,
            // OS Claim
            approvedFoodClaimAmount:
              singleClaimData[0].inputState.approvedFoodClaimAmount,
            approvedFoodClaimRemark:
              singleClaimData[0].inputState.foodClaimRemark,
            approvedHotelClaimAmount:
              singleClaimData[0].inputState.approvedHotelClaimAmount,
            approvedHotelClaimRemark:
              singleClaimData[0].inputState.hotelClaimRemark,
            approvedOthersClaimAmount:
              singleClaimData[0].inputState.approvedOthersClaimAmount,
            approvedOthersClaimRemark:
              singleClaimData[0].inputState.othersClaimRemark,
            approvedTotalClaimAmount:
              singleClaimData[0].inputState.approvedTotalClaimAmount,
            rejectedTotalClaimAmount:
              singleClaimData[0].inputState.rejectedTotalClaimAmount,
            claimID: singleClaimData[0].inputState.claimID,
            approvalType: 1, // Admin Claim
            totalAppliedClaimAmmount:
              singleClaimData[0].inputState.totalAppliedClaimAmmount,
            remark: singleClaimData[0].inputState.remark,
          };
          let requestoption = {
            method: "POST",
            mode: "cors",
            headers: travelizeAuth,
            body: JSON.stringify(claimApprovedData),
          };

          fetch(`${baseURL}/api/OSClaim/ApproveClaim`, requestoption)
            .then((res) => res.json())
            .then((res) => {
              if (res.success) {
                props.fetchManagerClaimDetails();
                props.goBackToClaimfromPendingPage();
                swal.showAlert(
                  "Success",
                  `Claim Approved ${res.message}`,
                  "success"
                );
              } else {
                props.fetchManagerClaimDetails();
                props.goBackToClaimfromPendingPage();
                swal.showAlert("Fail", `${res.errorMessage}`, "warning");
              }
            })
            .catch((error) => console.error(error));
        } else {
          swal.showAlert("Fail", "Please clear the error", "warning");
        }
      } else {
        swal.showAlert("Fail", "Please clear the error", "warning");
      }
    } else {
      let claimApprovedData = [];
      for (let i = 0; i < singleClaimData.length; i++) {
        if (
          singleClaimData[i].inputState.approvedLocalConveyanceClaimAmount <=
            singleClaimData[i].localClaim.amount &&
          singleClaimData[i].inputState.approvedFareClaimAmount <=
            singleClaimData[i].fareClaim.amount &&
          singleClaimData[i].inputState.approvedFoodClaimAmount <=
            singleClaimData[i].foodClaim.amount &&
          singleClaimData[i].inputState.approvedHotelClaimAmount <=
            singleClaimData[i].hotelClaim.amount &&
          singleClaimData[i].inputState.approvedOthersClaimAmount <=
            singleClaimData[i].othersClaim.amount
        ) {
          if (
            (singleClaimData[i].inputState.approvedFareClaimAmount <
            singleClaimData[i].fareClaim.amount
              ? singleClaimData[i].inputState.fareClaimRemark != ""
              : singleClaimData[i].inputState.fareClaimRemark == "" ||
                singleClaimData[i].inputState.fareClaimRemark != "") &&
            (singleClaimData[i].inputState.approvedLocalConveyanceClaimAmount <
            singleClaimData[i].localClaim.amount
              ? singleClaimData[i].inputState.localConveyanceClaimRemark != ""
              : singleClaimData[i].inputState.localConveyanceClaimRemark ==
                  "" ||
                singleClaimData[i].inputState.localConveyanceClaimRemark !=
                  "") &&
            (singleClaimData[i].inputState.approvedFoodClaimAmount <
            singleClaimData[i].foodClaim.amount
              ? singleClaimData[i].inputState.foodClaimRemark != ""
              : singleClaimData[i].inputState.foodClaimRemark == "" ||
                singleClaimData[i].inputState.foodClaimRemark != "") &&
            (singleClaimData[i].inputState.approvedHotelClaimAmount <
            singleClaimData[i].hotelClaim.amount
              ? singleClaimData[i].inputState.hotelClaimRemark != ""
              : singleClaimData[i].inputState.hotelClaimRemark == "" ||
                singleClaimData[i].inputState.hotelClaimRemark != "") &&
            (singleClaimData[i].inputState.approvedOthersClaimAmount <
            singleClaimData[i].othersClaim.amount
              ? singleClaimData[i].inputState.othersClaimRemark != ""
              : singleClaimData[i].inputState.othersClaimRemark == "" ||
                singleClaimData[i].inputState.othersClaimRemark != "")
          ) {
            claimApprovedData.push({
              date: new Date(),
              approvedFareClaimAmount:
                singleClaimData[i].inputState.approvedFareClaimAmount,
              approvedFareClaimRemark:
                singleClaimData[i].inputState.fareClaimRemark,
              approvedLocalClaimAmount:
                singleClaimData[i].inputState
                  .approvedLocalConveyanceClaimAmount,
              approvedLocalClaimRemark:
                singleClaimData[i].inputState.localConveyanceClaimRemark,
              approvedOSClaimAmount: 0,
              approvedOSClaimRemark: "",
              approvedFoodClaimAmount:
                singleClaimData[i].inputState.approvedFoodClaimAmount,
              approvedFoodClaimRemark:
                singleClaimData[i].inputState.foodClaimRemark,
              approvedHotelClaimAmount:
                singleClaimData[i].inputState.approvedHotelClaimAmount,
              approvedHotelClaimRemark:
                singleClaimData[i].inputState.hotelClaimRemark,
              approvedOthersClaimAmount:
                singleClaimData[i].inputState.approvedOthersClaimAmount,
              approvedOthersClaimRemark:
                singleClaimData[i].inputState.othersClaimRemark,
              approvedTotalClaimAmount:
                singleClaimData[i].inputState.approvedTotalClaimAmount,
              rejectedTotalClaimAmount:
                singleClaimData[i].inputState.rejectedTotalClaimAmount,
              claimID: singleClaimData[i].inputState.claimID,
              approvalType: 1, // Admin Claim
              totalAppliedClaimAmmount:
                singleClaimData[i].inputState.totalAppliedClaimAmmount,
              remark: singleClaimData[i].inputState.remark,
            });

            // console.log("APPROVE-SEND-DATA > 1:", claimApprovedData);

            let requestoption = {
              method: "POST",
              mode: "cors",
              headers: travelizeAuth,
              body: JSON.stringify(claimApprovedData),
            };

            fetch(`${baseURL}/api/OSClaim/BulkApproveClaim`, requestoption)
              .then((res) => res.json())
              .then((res) => {
                if (res.success) {
                  props.fetchManagerClaimDetails();
                  props.goBackToClaimfromPendingPage();
                  swal.showAlert(
                    "Success",
                    `BulkClaim Approved ${res.message}`,
                    "success"
                  );
                } else {
                  props.fetchManagerClaimDetails();
                  props.goBackToClaimfromPendingPage();
                  swal.showAlert("Fail", `${res.errorMessage}`, "warning");
                }
              })
              .catch((error) => console.error(error));
          } else {
            swal.showAlert("Fail", "Please clear the error", "warning");
          }
        } else {
          swal.showAlert("Fail", "Please clear the error", "warning");
        }
      }
      e.preventDefault();
    }
  };

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/OSUserWiseClaimExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${
        props.claimUserId
      }&StatusID=42&SearchStatusType=Admin&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "OS_Pending_ClaimDataBy_Admin.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      {props.pendingforApproval ? (
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}

            {/* Filter and Header Section */}
            <div className="claims-table">
              <Row className="page-filter-section">
                <Col md={5} className="cat-col">
                  <div className="page-header-text-div">
                    <div
                      className="back-btn"
                      onClick={props.goBackToClaimfromPendingPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      Pending for Approval by Admin
                    </h6>
                  </div>
                </Col>
                <Col md={7} className="cat-col">
                  <div className="filter-row-input-boxes-end">
                    <div className="from-to-date-select">
                      <div className="input-group-text" id="from-label">
                        From - To
                      </div>
                      <div className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate}
                          className="select from-date date-filter"
                          id="date-range-picker"
                          dateFormat="dd/MM/yyyy"
                          selectsRange
                          startDate={fromDate}
                          endDate={toDate}
                          onChange={(date) => setSelectSearchDrop(date)}
                          maxDate={new Date()}
                        />
                        <div>
                          <i
                            className="fa fa-calendar-o calander-icon"
                            style={{ right: "6px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <MiClearButton
                      onClickEffect={clearSerachBySelect}
                      btnName="Clear"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* Filter and Header Section */}

            {/* LEADS TABLE START */}
            <div className="claims-table">
              <div className="pendingfor-approval">
                <>
                  {isLoading ? (
                    <SkeletonPendingClaimHead />
                  ) : pendingForApprovalData !== "" &&
                    pendingForApprovalData !== null ? (
                    <div className="pendingapproval-records">
                      <div className="claim-user-details-img">
                        <img
                          className="pendingapproval-user-imgage"
                          src={profileimg}
                          alt="User Image"
                        />
                        <div className="pendingapproval-username-details">
                          <span>
                            <b>User Name :</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>{pendingForApprovalData[0]?.fullName}</>
                            )}
                          </span>
                          <span>
                            <b>Email ID :</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>{pendingForApprovalData[0]?.email}</>
                            )}
                          </span>
                          <span>
                            <b>Contact No.:</b> &nbsp;
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <>
                                {pendingForApprovalData[0]?.countryCode}
                                {pendingForApprovalData[0]?.contact}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="claim-total-by-section1">
                        <div>
                          Total Outstation :{" "}
                          {totalbySection.totalOutstation.toFixed(2)}
                        </div>
                        <div>
                          Total Lodging : {totalbySection.totalHotel.toFixed(2)}
                        </div>
                        <div>
                          Total Food : {totalbySection.totalFood.toFixed(2)}
                        </div>
                      </div>
                      <div className="claim-total-by-section2">
                        <div>
                          Total Local : {totalbySection.localTotal.toFixed(2)}
                        </div>
                        <div>
                          Total Others : {totalbySection.totalOthers.toFixed(2)}
                        </div>
                        <div>
                          Grand Total : &nbsp;
                          <b>
                            {(
                              totalbySection.localTotal +
                              totalbySection.totalHotel +
                              totalbySection.totalFood +
                              totalbySection.totalOutstation +
                              totalbySection.totalOthers
                            ).toFixed(2)}{" "}
                          </b>
                        </div>
                      </div>

                      <span className="claim-select-all">
                        Select All &nbsp;
                        <input
                          className="select-all-checkbox"
                          type="checkbox"
                          value=""
                          id="0"
                          onClick={(e) => {
                            setClaimNumber(e.target.checked, +0);
                          }}
                          checked={selectAllStatus}
                        />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
                <div
                  className="table-responsive pendingapproval-scrollbar"
                  onScroll={(e) =>
                    handleScroll(
                      e,
                      pendingForApprovalData,
                      totalData,
                      setLoading,
                      setPageSize,
                      PageSize
                    )
                  }
                >
                  {isLoading ? (
                    <SkeletonPendingTableData />
                  ) : pendingForApprovalData !== "" &&
                    pendingForApprovalData !== null ? (
                    pendingForApprovalData?.map((data, i) => (
                      <table className="table pendingapproval-table" key={i}>
                        <thead className="thead-light pendingapproval-thead">
                          <tr>
                            <th>
                              {i + 1}. Applied Date : &nbsp;
                              {moment(data?.updateDate).format("DD/MM/YYYY")}
                            </th>
                            <th>
                              Visit Purpose : &nbsp;
                              {data?.visitPurpose}
                            </th>
                            <th>
                              FromDate :{" "}
                              {moment(data?.fromDate).format("DD/MM/YYYY")}{" "}
                              &nbsp;
                            </th>
                            <th>
                              ToDate :{" "}
                              {moment(data?.toDate).format("DD/MM/YYYY")}
                            </th>
                            <th>No.Of Days : {data?.noOfDays}</th>
                            <th>
                              <input
                                className="select-checkbox"
                                type="checkbox"
                                onClick={(e) =>
                                  setClaimNumber(
                                    e.target.checked,
                                    data?.claimID
                                  )
                                }
                                checked={data?.inputState?.chkSelectClaim}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th>
                              FromLocation : &nbsp;
                              {data?.fromLocation}
                            </th>
                            <th>
                              ToLocation : &nbsp;
                              {data?.toLocation}
                            </th>
                            <th>Company Name : {data?.clientName} &nbsp;</th>
                            <th>Contact Person : {data?.contactPerson}</th>
                            <th>Status : {data?.byAdmin?.status}</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>Type</th>
                            <th>Attachment</th>
                            <th>Applied Amount</th>
                            <th>Approved Amount</th>
                            <th>Remarks</th>
                            <th></th>
                          </tr>
                        </thead>
                        {/* {data?.inputState?.claimID == data?.claimID && ( */}
                        <tbody>
                          <tr className="pendingapproval-row-data">
                            <td>{data?.fareClaim?.mot} Fare</td>
                            <td>
                              {data?.fareClaim?.attachment == "" ? (
                                <span
                                  class="material-symbols-outlined"
                                  title="No-attachment"
                                  style={{ fontSize: "18px" }}
                                >
                                  attach_file_off
                                </span>
                              ) : (
                                <span
                                  class="material-symbols-outlined"
                                  title="View"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.fareClaim?.attachment.split(",") ??
                                        ""
                                    );
                                  }}
                                >
                                  attach_file
                                </span>
                              )}
                            </td>
                            <td>
                              <>{data?.fareClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                className="form-control input-approve-amount"
                                type="number"
                                id={data?.claimID}
                                name="local"
                                value={
                                  data?.inputState?.approvedFareClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedFareClaimAmount",
                                    data?.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.fareClaim?.amount) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.fareClaim?.amount) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setOutStationClaimAlertMsg({
                                      claimId: data?.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setOutStationClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control form-control-sm remarks-td"
                                autoComplete="off"
                                type="text"
                                placeholder="Remarks"
                                value={data.inputState.fareClaimRemark}
                                onChange={(e) =>
                                  handleInput(
                                    e.target.value,
                                    "fareClaimRemark",
                                    data.claimID
                                  )
                                }
                              />
                              {outStationClaimAlertMsg?.claimId ==
                              data?.claimID ? (
                                <span className="error-input-message">
                                  {data.inputState.fareClaimRemark == "" ||
                                  data?.fareClaim?.amount <
                                    data?.inputState?.approvedFareClaimAmount
                                    ? outStationClaimAlertMsg?.message
                                    : ""}
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Local Conveyance </td>
                            <td>
                              {data?.localClaim?.attachment == "" ? (
                                <span
                                  class="material-symbols-outlined"
                                  title="No-attachment"
                                  style={{ fontSize: "18px" }}
                                >
                                  attach_file_off
                                </span>
                              ) : (
                                <span
                                  class="material-symbols-outlined"
                                  title="View"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.localClaim?.attachment.split(",") ??
                                        ""
                                    );
                                  }}
                                >
                                  attach_file
                                </span>
                              )}
                            </td>
                            <td>{data?.localClaim?.amount}</td>
                            <td>
                              <input
                                type="number"
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                name="outStation"
                                value={
                                  data.inputState
                                    .approvedLocalConveyanceClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedLocalConveyanceClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.localClaim?.amount) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.localClaim?.amount) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setLocalClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setLocalClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={
                                    data.inputState.localConveyanceClaimRemark
                                  }
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "localConveyanceClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {localClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data.inputState
                                      .localConveyanceClaimRemark == "" ||
                                    data?.localClaim?.amount <
                                      data?.inputState
                                        ?.approvedLocalConveyanceClaimAmount
                                      ? localClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Food</td>
                            <td>
                              {data?.foodClaim?.attachment == "" ? (
                                <span
                                  class="material-symbols-outlined"
                                  title="No-attachment"
                                  style={{ fontSize: "18px" }}
                                >
                                  attach_file_off
                                </span>
                              ) : (
                                <span
                                  class="material-symbols-outlined"
                                  title="View"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.foodClaim?.attachment.split(",") ??
                                        ""
                                    );
                                  }}
                                >
                                  attach_file
                                </span>
                              )}
                            </td>
                            <td>
                              <>{data?.foodClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                type="number"
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                name="food"
                                value={data.inputState.approvedFoodClaimAmount}
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedFoodClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.foodClaim?.amount) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.foodClaim?.amount) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setFoodClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setFoodClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.foodClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "foodClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {foodClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.foodClaimRemark == "" ||
                                    data?.foodClaim?.amount <
                                      data.inputState.approvedFoodClaimAmount
                                      ? foodClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Lodging</td>
                            <td>
                              {data?.hotelClaim?.attachment == "" ? (
                                <span
                                  class="material-symbols-outlined"
                                  title="No-attachment"
                                  style={{ fontSize: "18px" }}
                                >
                                  attach_file_off
                                </span>
                              ) : (
                                <span
                                  class="material-symbols-outlined"
                                  title="View"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.hotelClaim?.attachment.split(",") ??
                                        ""
                                    );
                                  }}
                                >
                                  attach_file
                                </span>
                              )}
                            </td>
                            <td>
                              <>{data?.hotelClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                type="number"
                                name="hotel"
                                value={data.inputState.approvedHotelClaimAmount}
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedHotelClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (value > data?.hotelClaim?.amount) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (value < data?.hotelClaim?.amount) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setHotelClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setHotelClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.hotelClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "hotelClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {hotelClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.hotelClaimRemark == "" ||
                                    data?.hotelClaim?.amount <
                                      data?.inputState?.approvedHotelClaimAmount
                                      ? hotelClaimAlertMsg.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>
                          <tr className="pendingapproval-row-data">
                            <td>Others</td>
                            <td>
                              {data?.othersClaim?.attachment == "" ? (
                                <span
                                  class="material-symbols-outlined"
                                  title="No-attachment"
                                  style={{ fontSize: "18px" }}
                                >
                                  attach_file_off
                                </span>
                              ) : (
                                <span
                                  class="material-symbols-outlined"
                                  title="View"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setClaimAttachmentmodel(
                                      !claimAttachmentmodel
                                    );
                                    setAttachmentUrl(
                                      data?.othersClaim?.attachment.split(
                                        ","
                                      ) ?? ""
                                    );
                                  }}
                                >
                                  attach_file
                                </span>
                              )}
                            </td>
                            <td>
                              <> {data?.othersClaim?.amount}</>
                            </td>
                            <td>
                              <input
                                id={data?.claimID}
                                className="form-control input-approve-amount"
                                type="number"
                                name="others"
                                value={
                                  data.inputState.approvedOthersClaimAmount
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value.trim() != ""
                                      ? +e.target.value
                                      : 0;
                                  handleInput(
                                    +e.target.value,
                                    "approvedOthersClaimAmount",
                                    data.claimID
                                  );
                                  if (e.target.value.trim() == "") {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter a value",
                                    });
                                  } else if (
                                    value > data?.othersClaim?.amount
                                  ) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not more than claim amount !",
                                    });
                                  } else if (
                                    value < data?.othersClaim?.amount
                                  ) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message: "Please enter remarks",
                                    });
                                  } else if (value < 0) {
                                    setOtherClaimAlertMsg({
                                      claimId: data.claimID,
                                      message:
                                        "Amount should not be less that 0 !",
                                    });
                                  } else {
                                    setOtherClaimAlertMsg({
                                      claimId: "",
                                      message: "",
                                    });
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <>
                                <input
                                  id={data?.claimID}
                                  className="form-control form-control-sm remarks-td"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Remarks"
                                  value={data.inputState.othersClaimRemark}
                                  onChange={(e) =>
                                    handleInput(
                                      e.target.value,
                                      "othersClaimRemark",
                                      data.claimID
                                    )
                                  }
                                />
                                {otherClaimAlertMsg.claimId == data.claimID ? (
                                  <span className="error-input-message">
                                    {data?.inputState?.othersClaimRemark ==
                                      "" ||
                                    data?.othersClaim?.amount <
                                      data.inputState.approvedOthersClaimAmount
                                      ? otherClaimAlertMsg?.message
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td></td>
                          </tr>

                          <tr className="pendingapproval-row-data total-value">
                            <td></td>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td>
                              <b>{data?.totalAmount}</b>
                            </td>
                            <td>
                              <b>
                                {data?.inputState?.approvedTotalClaimAmount}
                              </b>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    ))
                  ) : (
                    <div>
                      <span className="text-center" style={{ width: "100%" }}>
                        --- NO DATA FOUND ---
                      </span>
                    </div>
                  )}
                  {loading && <OnScrollLoading />}
                </div>
              </div>
              <div className="download-and-pagination">
                <SbDownloadBtn onClickEffect={exportExcelClaim} />
                <SbApproveBtn onClickEffect={(e) => approveClaimAmount(e)} />
              </div>
            </div>
            {/* PENDING CLAIM MANAGER TABLE END */}

            {/* PAGE CONTENT END*/}

            <>
              {downloadIsLoading && (
                <>
                  <DownloadLoading />
                </>
              )}
              {claimAttachmentmodel ? (
                <ViewClaimAttachmentmodel
                  claimAttachmentmodel={claimAttachmentmodel}
                  handleCloseviewattachment={handleCloseviewattachment}
                  attachmentUrl={attachmentUrl}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
export default PendingClaimByAdmin;
