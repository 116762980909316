import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/ReimbursementLimit.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import {
  optionsTravelTypes,
  optionsMOTTypes,
  optionsMeetStatusList,
  optionsReimburseTypes,
  optionsPageSizeList,
} from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { useNavigate } from "react-router-dom";
import {
  getSingleBasefare,
  loadBaseFare,
  loadDepartmentDrop,
  loadDesignationDrop,
  loadMotDrop,
  loadPageBreadCome,
  loadSingleBaseFare,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Footer from "../../Footer";
import ReimbursementLimitsModal from "./ReimbursementLimitsModal";
import ReimbursementLimitsTable from "./ReimbursementLimitsTable";
import { travelizeAuth } from "../../HeaderAuthentication";

function ReimbursementLimitsPage() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let tableHeader = [
    {
      name: "DEPARTMENT",
      align: "left",
      width: "150px",
      subHeading: [],
    },
    { name: "DESIGNATION", align: "left", width: "200px", subHeading: [] },
    {
      name: "LOCAL",
      align: "center",
      width: "300px",
      subHeading: [
        { name: "FOOD", align: "left", width: "100px" },
        { name: "HOTEL", align: "left", width: "100px" },
        { name: "OTHERS", align: "left", width: "100px" },
      ],
    },
    {
      name: "OUT STATION",
      align: "center",
      width: "460px",
      subHeading: [
        { name: "FOOD", align: "left", width: "100px" },
        { name: "HOTEL", align: "left", width: "100px" },
        { name: "LOCAL Conveyance", align: "left", width: "160px" },
        { name: "OTHERS", align: "left", width: "100px" },
      ],
    },
    { name: "ADDED BY", align: "left", width: "150px", subHeading: [] },
    { name: "STATUS", align: "center", width: "150px", subHeading: [] },
    { name: "ACTION", align: "center", width: "200px", subHeading: [] },
  ];

  let dispatch = useDispatch();
  const { departmentDrop } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);
  const { baseFareList } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activeStatus, setActiveStatus] = useState(2);
  const [MotId, setMotId] = useState(0);
  const [reimburseID, setReimburseID] = useState("");
  const [editReimburseID, setEditReimburseID] = useState("");
  const [clickType, setClickType] = useState("");
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(20);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reimburseMentData, setReimburseMentData] = useState([]);

  const [filterArray, setFilterArray] = useState([]);
  const [selectReimburse, setSelectReimburse] = useState({
    value: "",
    label: "",
  });
  const [selectTravelType, setSelectTravelType] = useState({
    value: "",
    label: "",
  });
  const [selectStatus, setSelectStatus] = useState({
    value: 0,
    label: "Select Status",
  });
  const [selectDepartment, setSelectDepartment] = useState({
    value: 0,
    label: "",
  });
  const [selectDesignation, setSelectDesignation] = useState({
    value: 0,
    label: "",
  });

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "Status") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "Status");
      } else {
        list = [];
      }
      list.push({ key: "Status", value: data.value });
      // console.log(data);
      setSelectStatus(data);
    } else if (type === "Department") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DepartmentID");
      } else {
        list = [];
      }
      list.push({ key: "DepartmentID", value: data.value });
      setSelectDepartment(data);
    } else if (type === "Designation") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "DesignationID");
      } else {
        list = [];
      }
      list.push({ key: "DesignationID", value: data.value });
      setSelectDesignation(data);
    }
    setFilterArray(list);
  };

  const editReimburse = () => {
    setShow(!show);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let breadCome = { mainpage: "BaseFare" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadMotDrop());
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
  }, []);

  useEffect(() => {
    fetchReimburseData(filterArray, PageSize, currentPage);
  }, [
    filterArray,
    PageSize,
    currentPage,
    selectStatus,
    selectDepartment,
    selectDesignation,
  ]);

  const fetchReimburseData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomeClaimLimit/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&DepartmentID=${selectDepartment.value}&DesignationID=${selectDesignation.value}&Status=${selectStatus.value}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("MERGING ARRAY:", res.result);
          setReimburseMentData(res.result);
          setIsLoading(false);
        } else {
          setReimburseMentData([]);
          setIsLoading(false);
        }
      });
    // dispatch(
    //   loadBaseFare(filterArray, currentPage, PageSize, setIsLoading, setLoading)
    // );
  };

  const [openFilterTab, setOpenFilterTab] = useState(false);

  const clearSerachBySelect = () => {
    setSelectStatus({ value: 0, label: "Select Status" });
    setSelectDepartment({ value: 0, label: "" });
    setSelectDesignation({ value: 0, label: "" });
    setFilterArray([]);
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          REIMBURSEMENT LIMIT
                        </h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row className="filters-row">
                          <Col md={8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={departmentDrop}
                                  placeholder="Select Department"
                                  value={
                                    selectDepartment.value && selectDepartment
                                  }
                                  onInputChange={departmentDrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Department")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={desigationdrop}
                                  placeholder="Select Designation"
                                  value={
                                    selectDesignation.value && selectDesignation
                                  }
                                  onInputChange={desigationdrop.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Designation")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="clients-filter-input-boxs dflex-j-start">
                              <div
                                style={{
                                  maxWidthidth: "150px",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={optionsMeetStatusList}
                                  placeholder="Select Status"
                                  value={
                                    selectStatus.value !== "" && selectStatus
                                  }
                                  onInputChange={optionsMeetStatusList.label}
                                  onChange={(data) =>
                                    setSelectSearchDrop(data, "Status")
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <ReimbursementLimitsTable
                  tableHeader={tableHeader}
                  reimburseMentData={reimburseMentData}
                  isLoading={isLoading}
                  editReimburse={editReimburse}
                  setEditReimburseID={setEditReimburseID}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                />
                {/*TABLE END */}

                <div className="download-and-pagination">
                  {/* <SbDownloadBtn onClickEffect={exportExcelBaseFare} /> */}
                </div>

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <ReimbursementLimitsModal
                    show={show}
                    editReimburseID={editReimburseID}
                    reimburseID={reimburseID}
                    setReimburseID={setReimburseID}
                    handleClose={handleClose}
                    setEditReimburseID={setEditReimburseID}
                    clickType={clickType}
                    setClickType={setClickType}
                    fetchReimburseData={fetchReimburseData}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ReimbursementLimitsPage;
