import React, { useState, useEffect, useRef } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Routes.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import { optionsRoutPlanStatus } from "../DropdownsData";
import AccessDenied from "../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import RouteMap from "./RouteMap";
import { MiClearButton } from "../SbButtons/SbCancelBtn";
import { Col, Row } from "react-bootstrap";
import SbAddBtn, { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import GoogleAccessModal from "../GoogleAccessModal";
import AllRoutesInMap from "./AllRoutesInMap";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import AddClientToRote from "./AddClientToRote";
import { useReactToPrint } from "react-to-print";
import { travelizeAuth } from "../HeaderAuthentication";

export default function RoutePlanDetails(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let distanceMatrix = sessionStorage.getItem("distanceMatrixID");
  const [mapView, setMapView] = useState(false);

  useEffect(() => {
    if (distanceMatrix == 1) {
      setMapView(true);
    } else {
      setMapView(false);
    }
  }, [distanceMatrix]);

  const [accessModal, setAccessModal] = useState(false);
  const giveAccess = () => {
    closeRouteMap();
    setAccessModal(true);
  };

  const closeAccess = () => {
    setOpenRouteMap(true);
    setAccessModal(false);
  };

  const mapAccesss = () => {
    props.setOpenRouteMap(true);
    setAccessModal(false);
    setMapView(true);
  };

  const currentDate = new Date();
  const tenDaysAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 10
  );

  const [routePlanData, setRoutePlanData] = useState([]);
  const [viewRouteID, setViewRouteID] = useState(0);
  const [viewClientID, setViewClientID] = useState(0);
  const [fromDate, setFromDate] = useState(props.fromDate);
  const [toDate, setToDate] = useState(props.toDate);
  const [searchVal, setSearchVal] = useState("");
  const [openRouteMap, setOpenRouteMap] = useState(false);
  const [routesInMap, setRoutesInMap] = useState(false);
  const [routeStatus, setRouteStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [clientLatLng, setClientLatLng] = useState([]);
  const [baseLocation, setBaseLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [addClientRouteModal, setAddClientRouteModal] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
  }, [props.viewRouteID, fromDate, toDate, PageSize, currentPage, searchVal]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/GetRouteClient?RouteId=${props.viewRouteID}&Text=${searchVal}&FromDate=${fromDate}&ToDate=${toDate}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoutePlanData(res.result);
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setRoutePlanData("");
          setIsLoading(false);
        }
      });
  };
  // GET PAGE END

  useEffect(() => {
    if (routePlanData) {
      let routeslatlngData = [];
      const routesData = routePlanData.sort((a, b) => {
        return a.baseClientDistance - b.baseClientDistance;
      });

      for (let ele of routesData) {
        routeslatlngData.push({
          lat: +ele.clientLoc.latitude,
          lng: +ele.clientLoc.longitude,
          location: ele.clientLoc.location,
          clientName: ele.clientName,
          distance: ele.baseClientDistance,
        });
        setBaseLocation({
          lat: +ele.baseLoc.latitude,
          lng: +ele.baseLoc.longitude,
          location: ele.baseLoc.location,
          userName: ele.userFullName,
          distance: 0.0,
        });
      }
      routeslatlngData.unshift({
        lat: +routesData[0]?.baseLoc.latitude,
        lng: +routesData[0]?.baseLoc.longitude,
        location: routesData[0]?.baseLoc.location,
        userName: routesData[0]?.userFullName,
        distance: 0.0,
      });
      setClientLatLng(routeslatlngData);
    }
  }, [props.viewRouteID, routePlanData]);

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const clearSerachBySelect = () => {
    setRouteStatus("");
    setFromDate(props.fromDate);
    setToDate(props.toDate);
  };

  const closeRouteMap = () => {
    setOpenRouteMap(false);
    setRoutesInMap(false);
  };

  const addClientToRouteModal = () => {
    setAddClientRouteModal(!addClientRouteModal);
  };

  const componentRef = useRef();

  const pdfDownload = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* MAIN BODY START */}
          <div>
            {/* PAGE CONTENT START */}
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="page-filter-section">
                    <Col md={3} className="p-0">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={props.backtoRoutes}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">ROUTE PLAN DETAILS</h6>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="input-div">
                        <input
                          type="search"
                          id="metricInfo_search_bar"
                          placeholder="Search Company / Number"
                          className="form-control setting-input"
                          value={searchVal}
                          onChange={(e) => setSearchVal(e.target.value)}
                        />
                        <i className="fa fa-search search-icon" />
                      </div>
                    </Col>

                    <Col md={5} className="p-0">
                      <div className="filter-row-input-boxes-end">
                        <div
                          className="header-filter-btn"
                          onClick={() => setRoutesInMap(true)}
                        >
                          <div className="filter-icon-text">
                            <i className="bi bi-pin-map-fill" />
                            &nbsp;All Route in Map
                          </div>
                        </div>
                        <div
                          className="header-filter-btn"
                          onClick={() => setFilter(!filter)}
                        >
                          <div className="filter-icon-text">
                            <i className="bi bi-sliders" />
                            <span>Filters</span>
                          </div>
                        </div>

                        <SbAddBtn
                          onClickEffect={addClientToRouteModal}
                          disableProp={
                            routePlanData == "" || routePlanData.length <= 0
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={optionsRoutPlanStatus}
                            placeholder="Select Status"
                            value={
                              routeStatus == ""
                                ? ""
                                : optionsRoutPlanStatus.value
                            }
                            onInputChange={optionsRoutPlanStatus.label}
                            onChange={(data) => setRouteStatus(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="routes-table">
                  <div
                    className="table-responsive routes-scrollbar"
                    ref={componentRef}
                    onScroll={(e) =>
                      handleScroll(
                        e,
                        routePlanData,
                        totalData,
                        setLoading,
                        setPageSize,
                        PageSize
                      )
                    }
                  >
                    <table
                      className="table routes-plan-table-data"
                      ref={componentRef}
                    >
                      <thead className="thead-light routes-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>COMPANY </th>
                            <th>LOCATION</th>
                            <th>MEETING DETAILS</th>
                            <th>STATUS</th>
                            <th>USER</th>
                            <th>
                              DISTANCE
                              <br />
                              <small>(APPROXIMATE )</small>
                            </th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : routePlanData !== "" ? (
                          routePlanData.map((data, i) => (
                            <tr
                              key={i}
                              onClick={() => {
                                setOpenRouteMap(!openRouteMap);
                                setViewClientID(data.clientID);
                                setViewRouteID(data.routeID);
                              }}
                            >
                              <td>
                                <div className="table-row-head-Text">
                                  {data?.clientName}
                                </div>
                                <div className="table-row-sub-text">
                                  {data?.mobile}
                                </div>
                              </td>
                              <td>
                                <p style={{ maxWidth: "350px" }}>
                                  <i className="fa fa-map-marker color-yellow" />{" "}
                                  &nbsp;
                                  {data?.clientLoc?.location}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {" "}
                                  Date : &nbsp;
                                  <strong>
                                    {data?.planedClientMeetings[0]?.meetingDate}
                                  </strong>
                                </p>
                                <p>
                                  Time : &nbsp;
                                  <strong>
                                    {data?.planedClientMeetings[0]?.meetingTime}
                                  </strong>
                                </p>
                              </td>
                              <td>
                                <span
                                  className={`status ${data?.planedClientMeetings[0]?.planingStatus}`}
                                >
                                  {data?.planedClientMeetings[0]?.planingStatus}
                                </span>
                              </td>
                              <td>{data?.userFullName}</td>
                              <td>
                                <p className="color-green">
                                  <strong>{data?.baseClientDistance} KM</strong>
                                </p>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              {" "}
                              --- NO DATA FOUND ---{" "}
                            </td>
                          </tr>
                        )}
                        {loading && <OnScrollLoading />}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <SbDownloadBtn onClickEffect={pdfDownload} />
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {openRouteMap ? (
              <RouteMap
                openRouteMap={openRouteMap}
                viewClientID={viewClientID}
                closeRouteMap={closeRouteMap}
                viewRouteID={viewRouteID}
                giveAccess={giveAccess}
                mapView={mapView}
              />
            ) : (
              ""
            )}
            {accessModal && (
              <GoogleAccessModal
                accessModal={accessModal}
                closeAccess={closeAccess}
                mapAccesss={mapAccesss}
              />
            )}
            {routesInMap ? (
              <AllRoutesInMap
                routesInMap={routesInMap}
                closeRouteMap={closeRouteMap}
                clientLatLng={clientLatLng}
                baseLocation={baseLocation}
              />
            ) : (
              ""
            )}
            <>
              {addClientRouteModal && (
                <AddClientToRote
                  addClientRouteModal={addClientRouteModal}
                  addClientToRouteModal={addClientToRouteModal}
                  routePlanData={routePlanData}
                  fromDate={props.fromDate}
                  toDate={props.toDate}
                  fetchRoutePlans={fetchRoutePlans}
                />
              )}
            </>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
