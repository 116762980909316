import React, { useState, useEffect, useRef } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Schedule.scss";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../BaseUrl";
import TimeRangeSlider from "react-time-range-slider";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import * as swal from "../../Consturl/SwalAlert";
import Footer from "../../Footer";
import { travelizeAuth } from "../../HeaderAuthentication";

function Schedule() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [buttonStatus, setButtonStatus] = useState({
    style: "on",
    dis_status: false,
  });
  const [ischeckedMonday, setIsCheckedMonday] = useState(null);
  const [ischeckedTuesday, setIsCheckedTuesday] = useState(null);
  const [ischeckedWednesday, setIsCheckedWednesday] = useState(null);
  const [ischeckedThursday, setIsCheckedThursday] = useState(null);
  const [ischeckedFriday, setIsCheckedFriday] = useState(null);
  const [ischeckedSaturday, setIsCheckedSaturday] = useState(null);
  const [ischeckedSunday, setIsCheckedSunday] = useState(null);
  const [workingHousrsData, setWorkingHousrsData] = useState({});
  const [sheduleTimeByDayMonday, setSheduleTimeByDayMonday] = useState({});
  const [sheduleTimeByDayTuesday, setSheduleTimeByDayTuesday] = useState({});
  const [sheduleTimeByDayWednesday, setSheduleTimeByDayWednesday] = useState(
    {}
  );
  const [sheduleTimeByDayThursday, setSheduleTimeByDayThursday] = useState({});
  const [sheduleTimeByDayFriday, setSheduleTimeByDayFriday] = useState({});
  const [sheduleTimeByDaySaturday, setSheduleTimeByDaySaturday] = useState({});
  const [sheduleTimeByDaySunday, setSheduleTimeByDaySunday] = useState({});

  const [showAlert, setShowAlert] = useState(false);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchWorkingHours();
  }, []);

  const fetchWorkingHours = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/WorkingHours/Get`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setWorkingHousrsData(res.result);
        setButtonStatus({
          style: res.result.enableStatus == 1 ? "off" : "on",
          dis_status: res.result.enableStatus == 1 ? true : false,
        });
        ConvertAllDays(res.result);
      });
  };

  const buttonOn = () => {
    setButtonStatus({ style: "on", dis_status: false });
    postWorkingHourStatus();
    postWorkingHours();
  };

  const buttonOff = () => {
    setButtonStatus({ style: "off", dis_status: true });
    postWorkingHourStatus();
    // postWorkingHours();
  };

  const checkUncheck = (event) => {
    switch (event) {
      case "monday":
        setIsCheckedMonday(!ischeckedMonday);
        break;
      case "tuesday":
        setIsCheckedTuesday(!ischeckedTuesday);
        break;
      case "wednesday":
        setIsCheckedWednesday(!ischeckedWednesday);
        break;
      case "thursday":
        setIsCheckedThursday(!ischeckedThursday);
        break;
      case "friday":
        setIsCheckedFriday(!ischeckedFriday);
        break;
      case "saturday":
        setIsCheckedSaturday(!ischeckedSaturday);
        break;
      case "sunday":
        setIsCheckedSunday(!ischeckedSunday);
        break;
    }
  };

  const timeChangeHandlerMonday = (time) => {
    setSheduleTimeByDayMonday({ value: time });
  };
  const timeChangeHandlerTuesday = (time) => {
    setSheduleTimeByDayTuesday({ value: time });
  };
  const timeChangeHandlerWednesday = (time) => {
    setSheduleTimeByDayWednesday({ value: time });
  };
  const timeChangeHandlerThursday = (time) => {
    setSheduleTimeByDayThursday({ value: time });
  };
  const timeChangeHandlerFriday = (time) => {
    setSheduleTimeByDayFriday({ value: time });
  };
  const timeChangeHandlerSaturday = (time) => {
    setSheduleTimeByDaySaturday({ value: time });
  };
  const timeChangeHandlerSunday = (time) => {
    setSheduleTimeByDaySunday({ value: time });
  };

  const changeStartHandler = (time) => {
    console.log("Start Handler Called", time);
  };

  const changeCompleteHandler = (time) => {
    console.log("Complete Handler Called", time);
  };

  const ConvertAllDays = (data) => {
    var splitTime = "09:00 AM-06:00 PM|False";
    const daysName = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    for (let day of daysName) {
      splitTimeFunction(day, data[day], splitTime);
    }
  };

  const splitTimeFunction = (dayname, value, defaultval) => {
    if (value === null || value === "") {
      value = defaultval;
    }
    let newData = value.split("|").splice(0, 1).join("");
    let newA = newData.split("-").splice(0, 1).join("");
    let newP = newData.split("-").splice(1, 1).join("");
    let holiday = value.split("|").splice(1, 1).join("").toLowerCase();
    let isHoliday = JSON.parse(holiday);
    let newVal = { value: { start: newA, end: newP } };

    switch (dayname) {
      case "monday":
        setSheduleTimeByDayMonday(newVal);
        setIsCheckedMonday(isHoliday);
        break;
      case "tuesday":
        setSheduleTimeByDayTuesday(newVal);
        setIsCheckedTuesday(isHoliday);
        break;
      case "wednesday":
        setSheduleTimeByDayWednesday(newVal);
        setIsCheckedWednesday(isHoliday);
        break;
      case "thursday":
        setSheduleTimeByDayThursday(newVal);
        setIsCheckedThursday(isHoliday);
        break;
      case "friday":
        setSheduleTimeByDayFriday(newVal);
        setIsCheckedFriday(isHoliday);
        break;
      case "saturday":
        setSheduleTimeByDaySaturday(newVal);
        setIsCheckedSaturday(isHoliday);
        break;
      case "sunday":
        setSheduleTimeByDaySunday(newVal);
        setIsCheckedSunday(isHoliday);
        break;
    }
  };

  // console.log("MONDAY:", workingHousrsData);

  const postWorkingHours = () => {
    let postWorkingHoursData = {
      enableStatus: 0,
      monday:
        sheduleTimeByDayMonday.value.start +
        "-" +
        sheduleTimeByDayMonday.value.end +
        "|" +
        ischeckedMonday,
      tuesday:
        sheduleTimeByDayTuesday.value.start +
        "-" +
        sheduleTimeByDayTuesday.value.end +
        "|" +
        ischeckedTuesday,
      wednesday:
        sheduleTimeByDayWednesday.value.start +
        "-" +
        sheduleTimeByDayWednesday.value.end +
        "|" +
        ischeckedWednesday,
      thursday:
        sheduleTimeByDayThursday.value.start +
        "-" +
        sheduleTimeByDayThursday.value.end +
        "|" +
        ischeckedThursday,
      friday:
        sheduleTimeByDayFriday.value.start +
        "-" +
        sheduleTimeByDayFriday.value.end +
        "|" +
        ischeckedFriday,
      saturday:
        sheduleTimeByDaySaturday.value.start +
        "-" +
        sheduleTimeByDaySaturday.value.end +
        "|" +
        ischeckedSaturday,
      sunday:
        sheduleTimeByDaySunday.value.start +
        "-" +
        sheduleTimeByDaySunday.value.end +
        "|" +
        ischeckedSunday,
    };
    // console.log("TIME-SET-POST-DATA:", postWorkingHoursData);
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(postWorkingHoursData),
    };
    fetch(`${baseURL}/api/WorkingHours/AddUpdateWorkingHours`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("SCHEDULE-TIME-SET-RES::", res);
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          swal.showAlert("Fail", `${res.message}`, "warning");
        }
      });
  };

  const postWorkingHourStatus = () => {
    let workinghrsOnOffData = {
      enableStatus: buttonStatus.dis_status ? 0 : 1,
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(workinghrsOnOffData),
    };
    fetch(
      `${baseURL}/api/WorkingHours/AddUpdateWorkingHoursStatus`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("SCHEDULE-TIME-STATUS-RES::", res);
      });
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">OPERATING HOURS</h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <span className="on-off-button">
                            <button
                              type="button"
                              className={`btn btn-on-button ${buttonStatus.style}`}
                              onClick={() => buttonOn()}
                            >
                              ON
                            </button>
                            <button
                              type="button"
                              className={`btn btn-off-button ${buttonStatus.style}`}
                              onClick={() => buttonOff()}
                            >
                              OFF
                            </button>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* LEADS TABLE START */}
                <div className="operating-hours-table">
                  <div
                    className="operating-hours-settings"
                    disabled={buttonStatus.dis_status}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="set-time-range monday">
                          <span className="day-by-set-time monday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Monday"
                                name="monday"
                                id="01"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedMonday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Monday"
                              >
                                Monday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedMonday ? (
                                <p>
                                  {sheduleTimeByDayMonday.value.start} -
                                  {sheduleTimeByDayMonday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedMonday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"monday"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerMonday}
                              // onMouseUp={handleChange}
                              step={15}
                              value={sheduleTimeByDayMonday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range tuesday">
                          <span className="day-by-set-time tuesday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Tuesday"
                                name="tuesday"
                                id="02"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedTuesday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Tuesday"
                              >
                                Tuesday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedTuesday ? (
                                <p>
                                  {sheduleTimeByDayTuesday.value.start} -
                                  {sheduleTimeByDayTuesday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedTuesday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"tuesday"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerTuesday}
                              step={15}
                              value={sheduleTimeByDayTuesday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range wednesday">
                          <span className="day-by-set-time wednesday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Wednesday"
                                name="wednesday"
                                id="03"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedWednesday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Wednesday"
                              >
                                Wednesday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedWednesday ? (
                                <p>
                                  {sheduleTimeByDayWednesday.value.start} -
                                  {sheduleTimeByDayWednesday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedWednesday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"time_range"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerWednesday}
                              step={15}
                              value={sheduleTimeByDayWednesday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range thursday">
                          <span className="day-by-set-time thursday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Thursday"
                                name="thursday"
                                id="04"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedThursday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Thursday"
                              >
                                Thursday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedThursday ? (
                                <p>
                                  {sheduleTimeByDayThursday.value.start} -
                                  {sheduleTimeByDayThursday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedThursday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"time_range"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerThursday}
                              step={15}
                              value={sheduleTimeByDayThursday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range friday">
                          <span className="day-by-set-time friday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Friday"
                                name="friday"
                                id="05"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedFriday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Friday"
                              >
                                Friday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedFriday ? (
                                <p>
                                  {sheduleTimeByDayFriday.value.start} -
                                  {sheduleTimeByDayFriday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedFriday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"time_range"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerFriday}
                              step={15}
                              value={sheduleTimeByDayFriday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range saturday">
                          <span className="day-by-set-time saturday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Saturday"
                                name="saturday"
                                id="06"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedSaturday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Saturday"
                              >
                                Saturday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedSaturday ? (
                                <p>
                                  {sheduleTimeByDaySaturday.value.start} -
                                  {sheduleTimeByDaySaturday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedSaturday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"time_range"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerSaturday}
                              step={15}
                              value={sheduleTimeByDaySaturday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="set-time-range sunday">
                          <span className="day-by-set-time sunday">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="Sunday"
                                name="sunday"
                                id="07"
                                onChange={(e) => {
                                  checkUncheck(e.target.name);
                                }}
                                checked={ischeckedSunday}
                              />
                              <label
                                className="form-check-label"
                                htmlfor="Sunday"
                              >
                                Sunday
                              </label>
                            </div>
                            <div className="time-set-status">
                              {ischeckedSunday ? (
                                <p>
                                  {sheduleTimeByDaySunday.value.start} -
                                  {sheduleTimeByDaySunday.value.end}
                                </p>
                              ) : (
                                <p>Holiday</p>
                              )}
                            </div>
                          </span>
                          {ischeckedSunday ? (
                            <TimeRangeSlider
                              disabled={false}
                              format={12}
                              maxValue={"23:59 PM"}
                              minValue={"00:00 AM"}
                              name={"time_range"}
                              onChangeStart={changeStartHandler}
                              onChangeComplete={changeCompleteHandler}
                              onChange={timeChangeHandlerSunday}
                              step={15}
                              value={sheduleTimeByDaySunday.value}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className="mt-4 search-bar-header"
                          style={{ float: "right" }}
                        >
                          <SBSaveUpdateBtn
                            onClickEffect={postWorkingHours}
                            btnName="Save"
                          />
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
                {/* LEADS TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Schedule;
