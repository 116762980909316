import React, { useEffect, useLayoutEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/SuperAdminPage.scss";
import { Link, useNavigate } from "react-router-dom";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import { Col, Form, Row } from "react-bootstrap";
import { TbTrekking, TbActivityHeartbeat } from "react-icons/tb";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GiCreditsCurrency } from "react-icons/gi";
import { useRef } from "react";
import {
  loadAdminAccess,
  loadGetOTP,
  loadLeadGenSubReportTableData,
  loadLeadsCardData,
  loadLeadsTableData,
  loadSupportAccess,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import OTPTimer from "./OTPTimer";
import * as HeaderAuthentication from "../../Components/HeaderAuthentication";
import moment from "moment/moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRandomColor } from "../FormattedAmount";
import SubScriptionDataCard, { AccountsCard } from "./SubScriptionDataCard";
import Footer from "../Footer";

export default function SuperAdminPage() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let tableHeader = [
    { name: "NAME", align: "left", width: "150px" },
    { name: "TRIAL", align: "left", width: "100px" },
    { name: "TIER", align: "left", width: "100px" },
    { name: "ELITE", align: "left", width: "100px" },
  ];
  let tableHeader1 = [
    { name: "NAME", align: "left", width: "150px" },
    { name: "Day", align: "left", width: "80px" },
    { name: "Month", align: "left", width: "80px" },
  ];

  let themeColor = localStorage.getItem("theme");
  let dispatch = useDispatch();

  const [otpError, setOtpError] = useState(false);

  const { access } = useSelector((state) => state.data);
  const { supportAccess } = useSelector((state) => state.data);
  const { otpData } = useSelector((state) => state.data);
  const { getLeadsCardData } = useSelector((state) => state.data);
  const { getLeadsTableData } = useSelector((state) => state.data);
  const { getLeadGenSubReportTableData } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(loadLeadsCardData());
    dispatch(loadLeadsTableData());
    dispatch(loadLeadGenSubReportTableData());
  }, []);

  const handleOtpChange = (index, value) => {
    setOtpError(false);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const result = otp.toString().replace(/,/g, "");

  const supportPassword = "123456";

  useEffect(() => {
    if (result && result.length === 6) {
      let DefaultOTP =
        moment(new Date()).format("DD") + moment(new Date()).format("YYYY");
      if (
        parseInt(otpData) === parseInt(result) ||
        parseInt(result) === parseInt(DefaultOTP) ||
        result === supportPassword
      ) {
        if (result === supportPassword) {
          dispatch(loadSupportAccess(true));
        } else {
          dispatch(loadAdminAccess(true));
        }
      } else {
        setOtpError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, otpData]);

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
    dispatch(loadAdminAccess(false));
    dispatch(loadSupportAccess(false));
  };
  // page navigation

  // OTP Timer
  const [showTimer, setShowTimer] = useState(false);

  const handleTimerExpire = () => {
    setShowTimer(false);
    // Handle what should happen when the timer expires (e.g., request a new OTP).
  };

  // get OTP Functions
  const [seconds, setSeconds] = useState(30);
  const [otpStatus, setOtpStatus] = useState(false);

  const getOtpForSuperAdmin = () => {
    setOtpStatus(true);
    setShowTimer(true);
    if (otpStatus) {
      setSeconds(60);
    }
    let request = {
      userName: "mjd@lobotus.com,dimpu.kumar@lobotus.in,",
    };
    let postRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderAuthentication.postTravelizeAuth,
      body: JSON.stringify(request),
    };
    dispatch(loadGetOTP(postRequest));
  };
  // get OTP Functions

  // const handleNavigate=()=>{
  //   Navigate('/allsubscriptiondetails',{ state: { subTypes: '1' } })
  // }

  const handleNavigate = (subscriptionType) => {
    Navigate("/allsubscriptiondetails", {
      state: { subTypes: subscriptionType },
    });
  };

  const extractValues = (str) => {
    if (!str) return { day: "N/A", month: "N/A" };
    const dayMatch = str.match(/Day:\s*(\d+)/);
    const monthMatch = str.match(/Month:\s*(\d+)/);
    const day = dayMatch ? dayMatch[1] : "N/A";
    const month = monthMatch ? monthMatch[1] : "N/A";
    return { day, month };
  };

  return (
    <div>
      <div id="main-page">
        {userDetails !== null && userDetails !== "" && loggedInRole !== 2 ? (
          <>
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
              toggleMenu={toggleMenu}
              menuCollapse={menuCollapse}
              toggleshift={toggleshift}
            />
            {/* TOPBAR END */}

            <Footer />

            {/* MAIN BODY START */}
            <div className="page-wrapper">
              {/* PAGE CONTENT START */}
              <div className={`main-body ${toggleshift.style}`}>
                <div className="page-content">
                  <div
                    className={`${
                      !access && !supportAccess ? "page-content-center" : ""
                    }`}
                  >
                    {!access && !supportAccess ? (
                      <div className="super-admin-page">
                        <Row>
                          <Col md={6}>
                            <div className="admin-login-img">
                              <img
                                src={`../../images/DefaultImages/superAdminLogin${
                                  themeColor ? themeColor : "light"
                                }.png`}
                                alt=""
                                style={{ width: "90%" }}
                              />
                            </div>
                          </Col>
                          <Col md={6} className="d-flex">
                            <div className="admin-otp-form">
                              <div className="page-heading hv-center">
                                <h6>Access To Super Admin Page</h6>
                              </div>
                              <p className="otp-text">
                                Please enter OTP to get access to Super admin
                                page
                              </p>

                              <Form className="admin-form">
                                <p>Enter OTP Here</p>
                                <div className="form-field-div">
                                  {otp.map((value, index) => (
                                    <input
                                      key={index}
                                      type="password"
                                      value={value}
                                      maxLength={1}
                                      className="form-control form add-basefare-input"
                                      ref={(input) =>
                                        (inputRefs.current[index] = input)
                                      }
                                      onChange={(e) =>
                                        handleOtpChange(index, e.target.value)
                                      }
                                      onKeyDown={(e) => handleKeyDown(index, e)}
                                    />
                                  ))}
                                </div>
                                {otpError && (
                                  <small
                                    id={`Error`}
                                    className="form-text text-muted "
                                  >
                                    Entered OTP is incorrect
                                  </small>
                                )}
                                {otpStatus ? (
                                  <>
                                    <OTPTimer
                                      seconds={seconds}
                                      setSeconds={setSeconds}
                                      onTimerExpire={handleTimerExpire}
                                      showTimer={showTimer}
                                      getOtpForSuperAdmin={getOtpForSuperAdmin}
                                    />
                                  </>
                                ) : (
                                  <SBSaveUpdateBtn
                                    btnName="Get OTP"
                                    onClickEffect={getOtpForSuperAdmin}
                                  />
                                )}
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div className="super-admin-page">
                        <div className="page-heading hv-center justify-content-start">
                          <div className="back-btn" onClick={goToBackPage}>
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6>SUPER ADMIN ACCESS CONTROL</h6>
                        </div>

                        {access && (
                          <>
                            <div className="card-row-nowrap-gap">
                              <SubScriptionDataCard
                                onClickCard={() => handleNavigate("1")}
                                cardHeading={"TRIAL SUBSCRIPTION"}
                                totalData={getLeadsCardData[0].trial}
                                thisWeekData={getLeadsCardData[0].trialThisWeek}
                              />
                              <SubScriptionDataCard
                                onClickCard={() => handleNavigate("2")}
                                cardHeading={"TIER SUBSCRIPTION"}
                                totalData={getLeadsCardData[0].tier}
                                thisWeekData={getLeadsCardData[0].tierThisWeek}
                              />

                              <SubScriptionDataCard
                                onClickCard={() => handleNavigate("3")}
                                cardHeading={"ELITE SUBSCRIPTION"}
                                totalData={getLeadsCardData[0].elite}
                                thisWeekData={getLeadsCardData[0].eliteThisWeek}
                              />

                              <SubScriptionDataCard
                                onClickCard={() => handleNavigate("5")}
                                cardHeading={"EXPIRED SUBSCRIPTION"}
                                totalData={getLeadsCardData[0].expired}
                                thisWeekData={null}
                              />

                              <SubScriptionDataCard
                                onClickCard={() => handleNavigate("4")}
                                cardHeading={"EXPIRING THIS WEEK"}
                                totalData={getLeadsCardData[0].expiring_Week}
                                thisWeekData={null}
                              />
                            </div>
                            <Row>
                              <Col md={5} className="pl-0">
                                <div className="passbook-third-div">
                                  <div className="passbook-line-chart-container pt-3 pb-2 pl-0 pr-0">
                                    <div className="passbook-line-chart">
                                      <div className="mi-subform-header">
                                        <div className="form-header-sub">
                                          <h6>
                                            Lead Gen Subscription Report
                                            <span></span>
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="claims-table">
                                        <Paper
                                          sx={{
                                            width: "100%",
                                            overflow: "hidden",
                                          }}
                                          className="table-main-div"
                                        >
                                          <TableContainer
                                            sx={{ maxHeight: 440 }}
                                          >
                                            <Table
                                              stickyHeader
                                              aria-label="sticky table table-responsive"
                                              className="basefare-table"
                                            >
                                              <TableHead className="custom-table-header">
                                                <TableRow>
                                                  {tableHeader1.map(
                                                    (name, i) => (
                                                      <TableCell
                                                        key={i}
                                                        align={name.align}
                                                        className={`thead-cell-products`}
                                                        style={{
                                                          minWidth: name.width,
                                                        }}
                                                      >
                                                        {name.name}
                                                      </TableCell>
                                                    )
                                                  )}
                                                </TableRow>
                                              </TableHead>

                                              <>
                                                {getLeadGenSubReportTableData &&
                                                getLeadGenSubReportTableData.length ===
                                                  0 ? (
                                                  <TableBody>
                                                    <TableRow align="center">
                                                      <TableCell
                                                        align="center"
                                                        colSpan={
                                                          tableHeader1.length
                                                        }
                                                      >
                                                        <div
                                                          style={{
                                                            padding:
                                                              "20px 20px",
                                                          }}
                                                        >
                                                          <img
                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                            alt=""
                                                            style={{
                                                              width: "10%",
                                                            }}
                                                          />
                                                          <br />
                                                          <span>
                                                            ---No Data Found---
                                                          </span>
                                                        </div>
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                ) : (
                                                  <TableBody>
                                                    {getLeadGenSubReportTableData &&
                                                      getLeadGenSubReportTableData.map(
                                                        (data, i) => {
                                                          const { day, month } =
                                                            extractValues(
                                                              data.subCount
                                                            );
                                                          return (
                                                            <TableRow
                                                              align="center"
                                                              key={i}
                                                              className="client-list-row-data"
                                                            >
                                                              <TableCell>
                                                                <div className="users-list-name">
                                                                  <div>
                                                                    {data.logo ? (
                                                                      <img
                                                                        className="table-data-users-imgages company-logo"
                                                                        // src={`${baseURL}/${data.logo}`}
                                                                        alt="client-profile"
                                                                      />
                                                                    ) : (
                                                                      <div
                                                                        className="table-data-users-imgages default-image"
                                                                        style={{
                                                                          backgroundColor:
                                                                            getRandomColor(
                                                                              i
                                                                            ),
                                                                        }}
                                                                      >
                                                                        {data?.source
                                                                          ?.charAt(
                                                                            0
                                                                          )
                                                                          ?.toUpperCase() ||
                                                                          ""}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                  <div
                                                                    className="table-row-head-Text"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {" "}
                                                                      {
                                                                        data?.source
                                                                      }
                                                                    </strong>
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                              <TableCell>
                                                                <div>{day}</div>
                                                              </TableCell>
                                                              <TableCell>
                                                                <div>
                                                                  <div>
                                                                    {month}
                                                                  </div>
                                                                </div>
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        }
                                                      )}
                                                  </TableBody>
                                                )}
                                              </>
                                            </Table>
                                          </TableContainer>
                                        </Paper>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={7} className="pr-0">
                                <div className="passbook-third-div">
                                  <div className="passbook-line-chart-container pt-3 pb-2 pl-0 pr-0">
                                    <div className="passbook-line-chart">
                                      <div className="mi-subform-header">
                                        <div className="form-header-sub">
                                          <h6>
                                            Subscription Convertions
                                            <span></span>
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="claims-table">
                                        <Paper
                                          sx={{
                                            width: "100%",
                                            overflow: "hidden",
                                          }}
                                          className="table-main-div"
                                        >
                                          <TableContainer
                                            sx={{ maxHeight: 440 }}
                                          >
                                            <Table
                                              stickyHeader
                                              aria-label="sticky table table-responsive"
                                              className="basefare-table"
                                            >
                                              <TableHead className="custom-table-header">
                                                <TableRow>
                                                  {tableHeader.map(
                                                    (name, i) => (
                                                      <TableCell
                                                        key={i}
                                                        align={name.align}
                                                        className={`thead-cell-products`}
                                                        style={{
                                                          minWidth: name.width,
                                                        }}
                                                      >
                                                        {name.name}
                                                      </TableCell>
                                                    )
                                                  )}
                                                </TableRow>
                                              </TableHead>

                                              <>
                                                {getLeadsTableData &&
                                                getLeadsTableData.length ===
                                                  0 ? (
                                                  <TableBody>
                                                    <TableRow align="center">
                                                      <TableCell
                                                        align="center"
                                                        colSpan={
                                                          tableHeader.length
                                                        }
                                                      >
                                                        <div
                                                          style={{
                                                            padding:
                                                              "20px 20px",
                                                          }}
                                                        >
                                                          <img
                                                            src="../../../../images/DefaultImages/NodataFound.jpg"
                                                            alt=""
                                                            style={{
                                                              width: "10%",
                                                            }}
                                                          />
                                                          <br />
                                                          <span>
                                                            ---No Data Found---
                                                          </span>
                                                        </div>
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                ) : (
                                                  <TableBody>
                                                    {getLeadsTableData &&
                                                      getLeadsTableData.map(
                                                        (data, i) => (
                                                          <TableRow
                                                            align="center"
                                                            key={i}
                                                            className="client-list-row-data"
                                                          >
                                                            <TableCell>
                                                              <div className="users-list-name">
                                                                <div>
                                                                  {data.logo ? (
                                                                    <img
                                                                      className="table-data-users-imgages company-logo"
                                                                      // src={`${baseURL}/${data.logo}`}
                                                                      alt="client-profile"
                                                                    />
                                                                  ) : (
                                                                    <div
                                                                      className="table-data-users-imgages default-image"
                                                                      style={{
                                                                        backgroundColor:
                                                                          getRandomColor(
                                                                            i
                                                                          ),
                                                                      }}
                                                                    >
                                                                      {data?.name
                                                                        .charAt(
                                                                          0
                                                                        )
                                                                        .toUpperCase()}
                                                                    </div>
                                                                  )}
                                                                </div>
                                                                <div>
                                                                  <div
                                                                    className="table-row-head-Text"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      {" "}
                                                                      {
                                                                        data?.name
                                                                      }
                                                                    </strong>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </TableCell>
                                                            <TableCell>
                                                              <div className="leads-card-price">
                                                                <span className="leads-card">
                                                                  <span>
                                                                    {data.trial}
                                                                  </span>
                                                                  <img
                                                                    src="../../images/leadsincrease.png"
                                                                    alt=""
                                                                  />
                                                                  <p>
                                                                    {
                                                                      data.trialThisWeek
                                                                    }
                                                                  </p>
                                                                </span>
                                                              </div>
                                                            </TableCell>
                                                            <TableCell>
                                                              <div className="leads-card-price">
                                                                <span className="leads-card">
                                                                  <span>
                                                                    {data.tier}
                                                                  </span>
                                                                  <img
                                                                    src="../../images/leadsincrease.png"
                                                                    alt=""
                                                                  />
                                                                  <p>
                                                                    {
                                                                      data.tierThisWeek
                                                                    }
                                                                  </p>
                                                                </span>
                                                              </div>
                                                            </TableCell>
                                                            <TableCell>
                                                              <div className="leads-card-price">
                                                                <span className="leads-card">
                                                                  <span>
                                                                    {data.elite}
                                                                  </span>
                                                                  <img
                                                                    src="../../images/leadsincrease.png"
                                                                    alt=""
                                                                  />
                                                                  <p>
                                                                    {
                                                                      data.eliteThisWeek
                                                                    }
                                                                  </p>
                                                                </span>
                                                              </div>
                                                            </TableCell>
                                                          </TableRow>
                                                        )
                                                      )}
                                                  </TableBody>
                                                )}
                                              </>
                                            </Table>
                                          </TableContainer>
                                        </Paper>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}

                        <div className="super-admin-controls">
                          <h4 className="accounts-header mt-2">ACCOUNTS</h4>
                          {supportAccess === false && access === true && (
                            <div className="superadmin-links hyperlinks">
                              <AccountsCard
                                linkUrl={"/Trial"}
                                icon={<i className="fa fa-bell" />}
                                cardHeading={"CREATE SUBSCRIPTION"}
                              />
                              <AccountsCard
                                linkUrl={"/allsubscriptiondetails"}
                                icon={<i className="fa fa-info" />}
                                cardHeading={"ALL SUBSCRIPTION DETAILS"}
                              />
                              <AccountsCard
                                linkUrl={"/adddropdowndata"}
                                icon={<i className="fa fa-globe" />}
                                cardHeading={"ADD COUNTRY STATE & CITY"}
                              />
                              <AccountsCard
                                linkUrl={"/subscription"}
                                icon={<i className="fa fa-globe" />}
                                cardHeading={"MODULE ACCESS"}
                              />
                              <AccountsCard
                                linkUrl={"/kmrefresh/meetings"}
                                icon={<i className="fa fa-handshake-o" />}
                                cardHeading={"MEETING KILOMETER REFRESH"}
                              />
                              <AccountsCard
                                linkUrl={"/kmrefresh/attendance"}
                                icon={<i className="fa fa-check-square-o" />}
                                cardHeading={"ATTENDANCE KILOMETER REFRESH"}
                              />
                            </div>
                          )}
                          <div className="superadmin-links popups">
                            <AccountsCard
                              linkUrl={"/googlecredits"}
                              icon={<GiCreditsCurrency className="fa" />}
                              cardHeading={"GOOGLE CREDITS"}
                            />
                            <AccountsCard
                              linkUrl={"/apicredits"}
                              icon={<i className="fa fa-hourglass-half" />}
                              cardHeading={"API CREDITS"}
                            />

                            {supportAccess === false && access === true && (
                              <>
                                <AccountsCard
                                  linkUrl={""}
                                  icon={<TbTrekking className="fa" />}
                                  cardHeading={"APP VERSION"}
                                />
                                <AccountsCard
                                  linkUrl={""}
                                  icon={<i className="fa fa-street-view" />}
                                  cardHeading={"CLIENT MAPPING"}
                                />
                                <AccountsCard
                                  linkUrl={""}
                                  icon={<i className="fa fa-address-card" />}
                                  cardHeading={"SUBSCRIPTION TYPE"}
                                />

                                <AccountsCard
                                  linkUrl={"/exception"}
                                  icon={
                                    <i className="fa fa-exclamation-circle" />
                                  }
                                  cardHeading={"EXCEPTIONS"}
                                />
                                <AccountsCard
                                  linkUrl={"/mobileException"}
                                  icon={
                                    <i className="fa fa-exclamation-triangle" />
                                  }
                                  cardHeading={"MOBILE EXCEPTIONS"}
                                />
                                <AccountsCard
                                  linkUrl={"/refferal"}
                                  icon={<i className="fa fa-inr" />}
                                  cardHeading={"REFFERAL"}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* PAGE CONTENT END*/}
                </div>
              </div>

              {/* MAIN BODY END */}
            </div>
          </>
        ) : (
          <AccessDenied />
        )}
      </div>
    </div>
  );
}
