import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import AddField from "./AddField";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import { travelizeAuth } from "../../HeaderAuthentication";
import { baseURL } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { optionsStatusList } from "../../DropdownsData";
import EditField from "./EditField";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

export default function CreateNewForm(props) {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formFieldsData, setFormFieldsData] = useState([]);
  const [customFieldID, setCustomFieldID] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const addFieldModuleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setEditShow(false);
  };

  useEffect(() => {
    fetchFormDetailsByFormName();
  }, [props.customPageId, PageSize, currentPage, activeStatus]);

  const fetchFormDetailsByFormName = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomeFields/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${activeStatus}&CustomePagesID=${props.customPageId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFormFieldsData(res.result);
          setIsLoading(false);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setLoading(false);
        } else {
          setFormFieldsData([]);
          setIsLoading(false);
          setLoading(false);
        }
      });
  };

  // Page navigation
  const goToBackPage = () => {
    props.setGotoCreateForm(true);
  };
  // page navigation

  return (
    <div>
      <div className="create-form-with-customfields-table">
        {/* Filter and Header Section */}
        <div className="user-table">
          <Row className="search-row">
            <Col md={3} className="cat-col">
              <div className="page-header-text-div">
                <div className="back-btn" onClick={goToBackPage}>
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <h6 className="page-header-text">
                  {" "}
                  FORM NAME :{" "}
                  {formFieldsData[0]?.customePageName ?? props.formNameCreated}
                </h6>
              </div>
            </Col>
            <Col md={5} className="cat-col"></Col>
            <Col md={4} className="cat-col">
              <div className="search-bar-header" style={{ float: "right" }}>
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsStatusList}
                    placeholder="Select Status"
                    value={activeStatus == 2 ? "" : optionsStatusList.value}
                    onInputChange={optionsStatusList.label}
                    onChange={(data) => setActiveStatus(data.value)}
                    isSearchable={true}
                  />
                  <SbAddBtn
                    onClickEffect={addFieldModuleOpen}
                    disableProp={props.addEditField}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="added-form-fields">
            <div
              className="table-responsive routes-scrollbar"
              onScroll={(e) =>
                handleScroll(
                  e,
                  formFieldsData,
                  totalData,
                  setLoading,
                  setPageSize,
                  PageSize
                )
              }
            >
              <table className="table routes-table-data created-forms-fields">
                <thead className="thead-light routes-thead">
                  {isLoading ? (
                    <>
                      <SkeletonStdTableHead />
                    </>
                  ) : (
                    <tr>
                      <th>SL.NO.</th>
                      <th>FIELD NAME</th>
                      <th>FIELD TYPE</th>
                      <th>ISREQUIRED</th>
                      <th>STATUS</th>
                      <th style={{ textAlign: "center" }}>ACTION</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {isLoading ? (
                    <>
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                      <SkeletonStdTableBody />
                    </>
                  ) : formFieldsData != "" ? (
                    formFieldsData.map((data, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{data.fieldName}</td>
                        <td>{data.fieldType}</td>
                        <td>
                          {data.isRequired == 1 ? "Mandatory" : "Optional"}
                        </td>
                        <td>
                          {" "}
                          {data?.status === 1 ? (
                            <span className="status-badge complete-status">
                              Active
                            </span>
                          ) : (
                            <span className="status-badge pending-status">
                              InActive
                            </span>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <div className="user-action-td d-flex">
                            {props.addEditField ? (
                              <div
                                className="user-edit-link disabled"
                                style={{ cursor: "pointer" }}
                              >
                                <i className="fa fa-lock"></i> Edit
                              </div>
                            ) : (
                              <div
                                className="user-edit-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setCustomFieldID(data.customeFieldID);
                                  setEditShow(true);
                                }}
                              >
                                <i className="fa fa-edit"></i> Edit
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {" "}
                        --- NO DATA FOUND ---{" "}
                      </td>
                    </tr>
                  )}
                  {loading && <OnScrollLoading />}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Filter and Header Section */}
      </div>
      <>
        {show ? (
          <AddField
            show={show}
            handleClose={handleClose}
            customPageId={props.customPageId}
            fetchFormDetailsByFormName={fetchFormDetailsByFormName}
          />
        ) : (
          ""
        )}

        {editShow ? (
          <EditField
            editShow={editShow}
            handleClose={handleClose}
            customFieldID={customFieldID}
            fetchFormDetailsByFormName={fetchFormDetailsByFormName}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
