import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Image, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore from "swiper";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "../../styles/ProductsViewPage.scss";
import "../../styles/Products.scss";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { loadTravelReportAttachments } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../BaseUrl";
import Skeleton from "react-loading-skeleton";
import TravelReportAttachmentView from "./TravelReportAttachmentView";
import "../../styles/TravelReport.scss";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function TravelReportAttachments(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();
  console.log(clientId, "clientId");
  const { getSingleTravelReportData } = useSelector((state) => state.data);
  console.log(getSingleTravelReportData, "getSingleTravelReportData");

  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // useEffect(() => {
  //     dispatch(loadTravelReportAttachments(setIsLoading))
  // }, [])

  const [imageClicked, setImageClicked] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState(null);

  const handleImageClicked = (imageUrl) => {
    setImageClicked(!imageClicked);
    setClickedImageUrl(imageUrl);
  };

  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <div className="clients-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    <div className="back-btn" onClick={goToBackPage}>
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <h6 className="page-header-text">
                      TRAVEL REPORT ATTACHMENTS--
                    </h6>
                  </div>
                </Col>
                <Col md={5} className="cat-col"></Col>
              </Row>
            </div>

            <div className="report-container">
              {!imageClicked ? (
                <>
                  <div className="report-container-main-image">
                    <Swiper
                      spaceBetween={10}
                      navigation={false}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper2"
                    >
                      {/* {props.getTravelAttachments && props.getTravelAttachments?.map((data, i) => (
                                                <SwiperSlide key={i}>
                                                    <img src={`${baseURL}/${data.hotelClaim?.attachment}`} alt=""
                                                        onClick={() => handleImageClicked(`${baseURL}/${data.hotelClaim?.attachment}`)}
                                                    />
                                                </SwiperSlide>
                                            ))} */}
                      {getSingleTravelReportData &&
                        Array.isArray(getSingleTravelReportData) &&
                        getSingleTravelReportData.map((reportData, i) => (
                          <SwiperSlide key={i}>
                            {reportData.foodClaimAttachment &&
                              reportData.foodClaimAttachment
                                .split(",")
                                .map((imageUrl, i) => (
                                  <img
                                    src={`${baseURL}/${imageUrl.trim()}`}
                                    alt=""
                                  />
                                ))}
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                  <div className="report-container-sub-image">
                    <Swiper
                      style={{
                        "--swiper-navigation-color": "#000000",
                        "--swiper-pagination-color": "#000000",
                        "--swiper-navigation-size": "15px",
                        "--swiper-navigation-sides-offset": "0px",
                      }}
                      onSwiper={setThumbsSwiper}
                      spaceBetween={5}
                      slidesPerView={3}
                      // navigation={true}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="mySwiper"
                    >
                      {getSingleTravelReportData &&
                        Array.isArray(getSingleTravelReportData) &&
                        getSingleTravelReportData?.map((reportData, index) => (
                          <SwiperSlide key={index}>
                            <div className="report-container-sub-inner-image">
                              {reportData.foodClaimAttachment &&
                                reportData.foodClaimAttachment
                                  .split(",")
                                  .map((imageUrl, i) => (
                                    <img
                                      src={`${baseURL}/${imageUrl.trim()}`}
                                      alt=""
                                    />
                                  ))}
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </>
              ) : (
                <TravelReportAttachmentView imageUrl={clickedImageUrl} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
