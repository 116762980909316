import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import FormNameModel from "./FormNameModel";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import { baseURL } from "../../BaseUrl";
import { travelizeAuth } from "../../HeaderAuthentication";
import Select from "react-select";
import { optionsStatusList } from "../../DropdownsData";
import moment from "moment";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

export default function CreatedFormNames(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [show, setShow] = useState(false);
  const [formNamesData, setFormNamesData] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [customPageID, setCustomPageID] = useState(null);
  const [loading, setLoading] = useState(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const createFormNameModel = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCustomPageID(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCustomFormNames();
  }, [PageSize, currentPage, activeStatus, searchVal]);

  const fetchCustomFormNames = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/CustomePages/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${activeStatus}&Text=${searchVal}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setFormNamesData(res.result);
          setIsLoading(false);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setLoading(false);
        } else {
          setFormNamesData([]);
          setIsLoading(false);
          setLoading(false);
        }
      });
  };

  return (
    <div>
      <div>
        {/* Filter and Header Section */}
        <div className="user-table">
          <Row className="search-row">
            <Col md={3} className="cat-col">
              <div className="page-header-text-div">
                <h6 className="page-header-text"> CUSTOM FORM NAMES </h6>
              </div>
            </Col>
            <Col md={5} className="cat-col">
              <div className="input-div">
                <input
                  type="search"
                  id="metricInfo_search_bar"
                  placeholder="Search Form Name"
                  className="form-control setting-input"
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                />
                <i className="fa fa-search search-icon"></i>
              </div>
            </Col>
            <Col md={4} className="cat-col">
              <div className="search-bar-header" style={{ float: "right" }}>
                <div className="page-top-bar-dropdowns page-top-Filters">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsStatusList}
                    placeholder="Select Status"
                    value={activeStatus == 2 ? "" : optionsStatusList.value}
                    onInputChange={optionsStatusList.label}
                    onChange={(data) => setActiveStatus(data.value)}
                    isSearchable={true}
                  />
                  <SbAddBtn onClickEffect={createFormNameModel} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* Filter and Header Section */}

        <div className="custom-forms-table">
          <div
            className="table-responsive routes-scrollbar"
            onScroll={(e) =>
              handleScroll(
                e,
                formNamesData,
                totalData,
                setLoading,
                setPageSize,
                PageSize
              )
            }
          >
            <table className="table routes-table-data">
              <thead className="thead-light routes-thead">
                {isLoading ? (
                  <>
                    <SkeletonStdTableHead />
                  </>
                ) : (
                  <tr>
                    <th>SL.NO.</th>
                    <th>FORM NAME</th>
                    <th>CREATED BY</th>
                    <th>ADDED ON</th>
                    <th>STATUS</th>
                    <th style={{ textAlign: "center" }}>FIELD COUNT</th>
                    <th style={{ textAlign: "center" }}>DATA COUNT</th>
                    <th style={{ textAlign: "center" }}>ACTION</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                    <SkeletonStdTableBody />
                  </>
                ) : formNamesData != "" ? (
                  formNamesData.map((data, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {" "}
                        <div className="table-row-head-Text">
                          {data?.pageName}
                        </div>
                      </td>
                      <td>{data?.addedByUserFullName}</td>
                      <td>
                        {moment(data?.addedOn, "DD/MM/YYYY").format(
                          "DD MMM YYYY"
                        )}
                      </td>
                      <td>
                        {data?.status === 1 ? (
                          <span className="status-badge complete-status">
                            Active
                          </span>
                        ) : (
                          <span className="status-badge pending-status">
                            InActive
                          </span>
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {data?.activeFieldCount}
                      </td>
                      <td style={{ textAlign: "center" }}>{data?.formCount}</td>
                      <td style={{ textAlign: "center" }}>
                        {data.customePagesID == 1 ? (
                          "-"
                        ) : (
                          <div className="user-action-td d-flex">
                            {data?.formCount == 0 ? (
                              <div
                                className="user-edit-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShow(true);
                                  setCustomPageID(data.customePagesID);
                                }}
                              >
                                <i className="fa fa-edit" /> Edit
                              </div>
                            ) : (
                              <div
                                className="user-edit-link disabled"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-lock"></i> Edit
                              </div>
                            )}
                            <div
                              className="user-edit-link"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.setGotoCreateForm(false);
                                props.setCustomPageId(data?.customePagesID);
                                props.setAddEditField(
                                  data?.formCount == 0 ? false : true
                                );
                              }}
                            >
                              <i className="bi bi-eye-fill"></i> View
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      {" "}
                      --- NO DATA FOUND ---{" "}
                    </td>
                  </tr>
                )}
                {loading && <OnScrollLoading />}
              </tbody>
            </table>
          </div>
          <div className="download-and-pagination">
            {/* <SbDownloadBtn
            // onClickEffect={}
            /> */}
          </div>
        </div>
      </div>
      <>
        {show ? (
          <FormNameModel
            show={show}
            handleClose={handleClose}
            setGotoCreateForm={props.setGotoCreateForm}
            setCustomPageId={props.setCustomPageId}
            setFormNameCreated={props.setFormNameCreated}
            customPageID={customPageID}
            fetchCustomFormNames={fetchCustomFormNames}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}
