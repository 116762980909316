import React, { useState } from "react";
import { Form, FloatingLabel } from "react-bootstrap";
import ConfirmModel from "../../ConfirmModel";

export default function AccSettingComp(props) {
  const [confirmModel, setConfirmModel] = useState(false);

  const onValueChange = (e, type) => {
    switch (type) {
      case "meetingRange":
        props.setMeetingRange(e.target.value);
        break;
      case "claimTwoLevel":
        props.setTwoLevelClaim(e.target.value);
        break;
      case "useMappingType":
        props.setMappingType(e.target.value);
        break;
      case "manualEntry":
        props.setDirectVisit(+e.target.value);
        break;
      case "baseLocationRestriction":
        props.setBaseLocationRestriction(e.target.value);
        break;
      case "baseLocationRestrictionCheckOut":
        props.setBaseLocationRestrictionForCheckOut(e.target.value);
        break;
    }
  };

  const showConfirmModal = () => {
    setConfirmModel(true);
  };
  const handleClose = () => {
    setConfirmModel(false);
  };

  return (
    <>
      <div className="account_setting_comp">
        <div className="setting-header">
          <h6>{props.settingName}</h6>
        </div>
        <div className="setting-body">
          <div className="sub-heading">{props.subHeading}</div>
          <div className="setting-options">
            {props.settingsOptions.map((item, i) => (
              <div key={i}>
                <Form.Check
                  inline
                  label={item?.label}
                  value={item?.value}
                  name={props?.type}
                  type="radio"
                  id={`inlineradio-${props.type}-${item?.label}`}
                  onChange={(e) => onValueChange(e, props.type)}
                  checked={item.value == props.radioValue ? true : false}
                />
              </div>
            ))}
          </div>
          {props.type === "useMappingType" && (
            <span className="note">
              <strong className="color-green">* No Rule : </strong>All company
              will be visible to all users.
            </span>
          )}
          {props.type === "baseLocationRestriction" &&
            props.radioValue === "Yes" && (
              <div className="col-lg-6 col">
                <div className="mb-3 mt-2">
                  <FloatingLabel
                    label="Define Radius for Check-In (km's)"
                    className={`${
                      props.checkInRadius !== null
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      className="form-control add-user-input"
                      placeholder="Enter check-in radius (km's)"
                      value={props.checkInRadius}
                      onChange={(event) =>
                        props.setCheckInRadius(event.target.value)
                      }
                    />
                  </FloatingLabel>
                </div>
              </div>
            )}
          {props.type === "baseLocationRestrictionCheckOut" &&
            props.radioValue === "Yes" && (
              <div className="col-lg-6 col">
                <div className="mb-3 mt-2">
                  <FloatingLabel
                    label="Define Radius for Check-Out (km's)"
                    className={`${
                      props.checkOutRadius !== null
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      className="form-control add-user-input"
                      placeholder="Define check-out radius (km's)"
                      value={props.checkOutRadius}
                      onChange={(event) =>
                        props.setCheckOutRadius(event.target.value)
                      }
                    />
                  </FloatingLabel>
                </div>
              </div>
            )}
          <div className="update-button">
            <button
              className="btn settings-update-btn"
              onClick={showConfirmModal}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {confirmModel && (
        <ConfirmModel
          show={confirmModel}
          handleClose={handleClose}
          confirmationMessage={"Are you sure want to update account settings?"}
          confirmAction={props.settingsUpdateOnClick}
        />
      )}
    </>
  );
}
