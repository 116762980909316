import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/customField.scss";
import "../../../styles/category.scss";
import CustomFieldTable from "./CustomFieldTable";
import Select from "react-select";
import {
  loadCustomFields,
  loadSingleField,
  loadSubscriptionBySubsId,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import AddCustomField from "./AddCustomField";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";

function CreateCustomFields() {
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let tableHeader2 = [
    "Sl.No",
    "Field Name",
    "Field Type",
    "Link With Page",
    "Action",
  ];

  let tableHeader = [
    "Sl.No",
    "Field Name",
    "Field Type",
    "Page Name",
    "IsRequired",
    "Status",
    "Action",
  ];

  const { allSubsById } = useSelector((state) => state.data);
  const { customFields } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  let trueDataArray = [];
  if (customFields && customFields.length != 0) {
    trueDataArray = customFields.filter((item) => item.status === 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadSubscriptionBySubsId(resPassData.subscriptionId));
    fetchCustomFields();
  }, [PageSize, currentPage]);

  const fetchCustomFields = () => {
    dispatch(
      loadCustomFields(
        PageSize,
        currentPage,
        setTotalData,
        setIsLoading,
        setLoading
      )
    );
  };

  const [userList, setUserList] = useState([
    { value: "", label: "", mainModule: "" },
  ]);

  useEffect(() => {
    if (allSubsById) {
      let optiondata = [];
      allSubsById.map((data) => {
        if (data.moduleID === 7) {
          optiondata.push({
            value: data.moduleID,
            label: data.moduleName,
            mainModule: data?.mainModuleStatus,
          });
        }
      });
      setUserList(optiondata);
    }
  }, [allSubsById]);

  const formatOptionLabel = ({ label, client }) => (
    <div>
      <div>{label}</div>
      <div>{client}</div>
    </div>
  );

  // const [selectedPage, setSelectedPage] = useState('')

  // const setFilterUserId = (selected) => {
  //     setSelectedPage(selected)
  // }

  const [show, setShow] = useState(false);

  const addFieldModuleOpen = () => {
    setShow(true);
  };

  const addFieldModuleClose = () => {
    setShow(false);
  };

  const [editId, setEditId] = useState("");
  const editCustomFiled = (id) => {
    setEditId(id);
    setShow(true);
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {show && (
        <AddCustomField
          show={show}
          addFieldModuleClose={addFieldModuleClose}
          editId={editId}
          setEditId={setEditId}
          fetchCustomFields={fetchCustomFields}
          customFieldsActiveLength={trueDataArray.length}
        />
      )}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <Row className="search-row">
              <Col md={2} className="cat-col">
                <div className="page-header-text-div">
                  <div className="back-btn" onClick={goToBackPage}>
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className="page-header-text">Custom Fields</h6>
                </div>
              </Col>
              <Col md={10} className="custom-right-col">
                {/* <div className="page-name-custom">
                  <h6>Page Name:&nbsp;&nbsp;</h6>
                  <h6>
                    <b>Meetings</b>
                  </h6>
                </div> */}
                {/* <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={userList}
                                    placeholder="Select Page"
                                    // value={filterUserId === "" ? "" : userList.value}
                                    // onInputChange={userList.label}
                                    formatOptionLabel={formatOptionLabel}
                                    onChange={setFilterUserId}
                                    isSearchable={true}
                                /> */}
                <SbAddBtn
                  onClickEffect={addFieldModuleOpen}
                  // disableProp={trueDataArray.length >= 5}
                />
              </Col>
            </Row>
            <div>
              <Row>
                {/* <Col md={6} className='cat-col'>
                                    <div className='custom-left-header'>
                                        <h6><b>Master Fields</b></h6>
                                    </div>
                                    <CustomFieldTable tableHeader={tableHeader} type="master" />
                                </Col> */}
                {/* <Col md={1} className='cat-col'>
                                    <div className='adding-field-section'>
                                        <div className='vline'></div>
                                        <div>
                                            <Button veriant="primary" className=''>
                                                Add
                                            </Button>
                                        </div>
                                        <div className='vline'></div>
                                    </div>
                                </Col> */}
                <Col md={12} className="cat-col">
                  {/* <div className='custom-right-header'>
                                        <h6><b>Select Page</b></h6>
                                        <div className="page-top-bar-dropdowns sp-custom-field-select">
                                            <Select
                                                className="react-select-container-list"
                                                classNamePrefix="react-select-list"
                                                options={userList}
                                                placeholder="Select Page"
                                                // value={filterUserId === "" ? "" : userList.value}
                                                // onInputChange={userList.label}
                                                formatOptionLabel={formatOptionLabel}
                                                onChange={setFilterUserId}
                                                isSearchable={true}
                                            />
                                        </div>
                                    </div> */}
                  <CustomFieldTable
                    isLoading={isLoading}
                    loading={loading}
                    setLoading={setLoading}
                    setIsLoading={setIsLoading}
                    setPageSize={setPageSize}
                    PageSize={PageSize}
                    totalData={totalData}
                    tableHeader={tableHeader}
                    type="page"
                    customFields={customFields && customFields}
                    editCustomFiled={editCustomFiled}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CreateCustomFields;
