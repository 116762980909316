import React, { useState, useEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  loadDepartmentDrop,
  loadKpaData,
  loadUserDropdown,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import DatePicker from "react-datepicker";
import moment from "moment";
import KpaTable from "./KpaTable";
import AddKpaModel from "./AddKpaModel";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import SbCancelBtn, { MiClearButton } from "../../SbButtons/SbCancelBtn";
import UpdateKpaModel from "./UpdateKpaModel";
import Select from "react-select";
import ViewKpaData from "./ViewKpaData";
import Footer from "../../Footer";

function KPA() {
  let tableHeader = [
    { name: "USERS", align: "left", width: "150px" },
    { name: "DEPARTMENT", align: "left", width: "150px" },
    { name: "ADDED ON", align: "left", width: "150px" },
    { name: "PERFORMANCE PERIOD", align: "left", width: "150px" },
    { name: "USER COMMENT", align: "left", width: "200px" },
    { name: "RATINGS", align: "left", width: "200px" },
    { name: "Action", align: "center", width: "200px" },
  ];

  let dispatch = useDispatch();
  const { getKpa } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterArray, setFilterArray] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  //code for search bar

  const [searchValue, setSearchValue] = useState("");

  const [updateShow, setUpdateShow] = useState(false);
  const [kpaId, setKpaId] = useState();
  const [showKpa, setShowKpa] = useState(false);

  const [openFilterTab, setOpenFilterTab] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadUserDropdown());
    dispatch(loadDepartmentDrop());
  }, []);

  //code for edit

  const viewKpaTargetData = (kpaid) => {
    navigate(`/viewKpaTarget/${kpaid}`);
  };

  const viewKpa = (kpaid) => {
    setKpaId(kpaid);
    setShowKpa(!showKpa);
  };

  const editKpa = (kpaid) => {
    setKpaId(kpaid);
    setShow(true);
    setShowKpa(false);
  };

  const upDateStatus = (kpaid) => {
    setKpaId(kpaid);
    setUpdateShow(true);
  };
  const handleCloseUpdate = () => {
    setKpaId("");
    setUpdateShow(!updateShow);
  };

  const handleCloseViewKpaDetails = () => {
    setKpaId("");
    setShowKpa(false);
  };

  //code for filter

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Text");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Text", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Text");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list = [];
    if (type === "user") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "UserId");
      }
      list.push({ key: "UserId", value: data.value });
      setFilterUserId(data);
    } else if (type === "department") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "departmentId");
      }
      list.push({ key: "departmentId", value: data.value });
      setDepartmentId(data);
    } else if (type === "date") {
      const [start, end] = data;
      if (filterArray.length !== 0) {
        if (start) {
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if (end) {
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if (start && end == null) {
        list.push({
          key: "FromDate",
          value: moment(new Date(start)).format("DD/MM/YYYY"),
        });
      }
      setFromDate(start);
      if (end) {
        list.push({
          key: "ToDate",
          value: moment(new Date(end)).format("DD/MM/YYYY"),
        });
      }
      setToDate(end);
    }
    setFilterArray(list);
  };

  useEffect(() => {
    applyFilters(filterArray, currentPage, PageSize);
  }, [filterArray, currentPage, PageSize]);

  const applyFilters = () => {
    dispatch(
      loadKpaData(filterArray, currentPage, PageSize, setIsLoading, setLoading)
    );
  };

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setDepartmentId("");
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray([]); // Clear the filter array
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">
                          KEY PERFORMANCE AREA
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="search"
                          placeholder="Find a User"
                          id="metricInfo_search_bar"
                          className="form-control setting-input"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={userDropdown}
                            placeholder="Select User"
                            value={filterUserId}
                            onInputChange={userDropdown.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "user")
                            }
                            isSearchable={true}
                          />
                        </div>
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={departmentDrop}
                            placeholder="Select Department"
                            value={departmentId}
                            onInputChange={departmentDrop.label}
                            onChange={(data) =>
                              setSelectSearchDrop(data, "department")
                            }
                            isSearchable={true}
                          />
                        </div>
                        <div className="from-to-date-select">
                          <div className="input-group-text" id="from-label">
                            From - To
                          </div>
                          <div className="date-picker-with-icon">
                            <DatePicker
                              selected={fromDate}
                              className="select from-date date-filter"
                              id="date-range-picker"
                              dateFormat="dd/MM/yyyy"
                              selectsRange
                              startDate={fromDate}
                              endDate={toDate}
                              onChange={(data) =>
                                setSelectSearchDrop(data, "date")
                              }
                              maxDate={new Date()}
                            />
                            <div>
                              <i
                                className="fa fa-calendar-o calander-icon"
                                style={{ right: "6px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <MiClearButton
                        onClickEffect={clearSerachBySelect}
                        btnName="Clear"
                      />
                    </div>
                  )}
                </div>

                {/*TABLE START */}
                <KpaTable
                  tableHeader={tableHeader}
                  editKpa={editKpa}
                  // isLoading={isLoading}
                  getKpa={getKpa}
                  viewKpa={viewKpa}
                  upDateStatus={upDateStatus}
                  viewKpaTargetData={viewKpaTargetData}
                />
                {/* <ViewKpaTarget
                  tableHeader={tableHeader}
                  editKpa={editKpa}
                  // isLoading={isLoading}
                  getKpa={getKpa}
                  viewKpa={viewKpa}
                  upDateStatus={upDateStatus}

                /> */}
                {/*TABLE END */}

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddKpaModel
                    show={show}
                    kpaId={kpaId}
                    setKpaId={setKpaId}
                    handleClose={handleClose}
                    applyFilters={applyFilters}
                  />
                )}
                {updateShow && (
                  <UpdateKpaModel
                    show={updateShow}
                    kpaId={kpaId}
                    handleClose={handleCloseUpdate}
                    applyFilters={applyFilters}
                  />
                )}
                {showKpa && (
                  <ViewKpaData
                    showModal={showKpa}
                    kpaId={kpaId}
                    setKpaId={setKpaId}
                    setShowModal={setShowKpa}
                    editKpa={editKpa}
                    handleClose={handleCloseViewKpaDetails}
                    applyFilters={applyFilters}
                  />
                )}
                {/* {showKpa && (
                  <ViewKpaDetails
                    show={showKpa}
                    kpaId={kpaId}
                    handleClose={handleCloseViewKpaDetails}
                    applyFilters={applyFilters}
                  />
                )} */}

                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <Footer />
    </div>
  );
}

export default KPA;
