import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/MediaFiles/Media.scss";
import { Routes, Route } from "react-router-dom";
import ResetPasswordPage from "./Components/ResetPasswordPage";
import CheckSubscription from "./Components/CheckSubscription";
import Dashboard from "./Components/Dashboard/Dashboard";
import TrackByUser from "./Components/Dashboard/TrackByUser";
import Users from "./Components/Users/Users";
import ViewSubordinates from "./Components/Users/ViewSubbordinates";
import Tracker from "./Components/Tracker/Tracker";
import AttendanceMonthly from "./Components/Attendance/AttendanceMonthly";
import AttendanceFullday from "./Components/Attendance/AttendanceFullday";
import Leaves from "./Components/Leaves/Leaves";
import Meetings from "./Components/Meetings/Meetings";
import Clients from "./Components/Clients/Clients";
import LocalClaim from "./Components/LocalClaim/LocalClaim";
import MeetingDetailsModel from "./Components/LocalClaim/MeetingDetailsModel";
import Schedule from "./Components/Settings/Schedule/Schedule";
import TrackClients from "./Components/Settings/TrackClients/TrackClients";
import MeetingPurpose from "./Components/Settings/MeetingPurpose/MeetingPurpose";
import BaseFare from "./Components/Settings/BaseFare/BaseFare";
import Profile from "./Components/Settings/Profile/Profile";
import AccountDetails from "./Components/Settings/AccountDetails/AccountDetails";
import PageNotFound from "./PageNotFound";
import CreateSubscription from "./Components/PrivatePages/CreateSubscription";
import OutStationClaim from "./Components/OutStationClaim/OutStationClaim";
import KilometerRefreshPage from "./Components/PrivatePages/KilometerRefreshPage";
import ExceptionHandler from "./Components/PrivatePages/ExceptionHandler";
import KilometerRefreshAttendance from "./Components/PrivatePages/KilometerRefreshAttendance";
import SubscriptionDetails from "./Components/PrivatePages/SubscriptionDetails";
import SessionTimeout from "./Components/SessionTimeout";
import AccountSettings from "./Components/Settings/AccountSettings/AccountSettings";
import DeviceDetails from "./Components/Settings/DeviceDetails/DeviceDetails";
import Products from "./Components/Products/Products";
import Spares from "./Components/Spares/Spares";
import AddDropdownData from "./Components/PrivatePages/AddDropdownData";
import Department from "./Components/Settings/Department/Department";
import Designation from "./Components/Settings/Designation/Designation";
import MeetingManagement from "./Components/SubscriptionModule/MeetingManagement";
import Role from "./Components/Settings/Role/Role";
import Amc from "./Components/AMC/Amc";
import SuscriberPage from "./Components/SubscriptionModule/SuscriberPage";
import SettingPage from "./Components/Settings/SettingPage";
import UserTrackerPage from "./Components/UserTrackerPage";
import ClientCategory from "./Components/Settings/Category/ClientCategory";
import ClientStage from "./Components/Settings/Stage/ClientStage";
import Complaints from "./Components/Complaints/Complaints";
import Projects from "./Components/PMS/Projects/Projects";
import Phase from "./Components/PMS/Phase/Phase";
import Tasks from "./Components/PMS/Tasks/Tasks";
import Reports from "./Components/PMS/Reports/Reports";
import PaymentDetails from "./Components/PMS/Reports/PaymentDetails";
import TextForm from "./Components/TextForm";
import Holiday from "./Components/Settings/Holidays/Holiday";
import Shift from "./Components/Settings/Shift/Shift";
import ProductCategory from "./Components/Settings/Product Category/ProductCategory";
import Orders from "./Components/Orders/Orders";
import GoogleCredits from "./Components/SuperAdmin/GoogleCredits/GoogleCredits";
import ApiCredits from "./Components/SuperAdmin/ApiCredits/ApiCredits";
import CartPage from "./Components/Products/CartPage";
import RoutePlan from "./Components/Routes/RoutePlan";
import OrderDetails from "./Components/Orders/OrderDetails";
import GoogleAPIHitCount from "./Components/SuperAdmin/GoogleHitCounts/GoogleAPIHitCount";
import CreateCustomFields from "./Components/Settings/CustomFields/CreateCustomFields";
import ExceptionPage from "./Components/SuperAdmin/Exceptions/ExceptionPage";
import MobileException from "./Components/SuperAdmin/MobileExceptions/MobileException";
import UnAutherized from "./Components/UnAutherized";
import ProductsViewPage from "./Components/Products/ProductsViewPage";
import SuperAdminPage from "./Components/SuperAdmin/SuperAdminPage";
import LeaveType from "./Components/Settings/LeaveType/LeaveType";
import Refferal from "./Components/SuperAdmin/Referal/Refferal";
import ClaimSummary from "./Components/LocalClaim/ClaimSummary";
import CustomForm from "./Components/Settings/CustomForm/CustomForm";
import LiveChatBoot from "./Components/LiveChatBoot";
import MapByCategory from "./Components/Settings/MapByCategory/MapByCategory";
import TrainChatBot from "./Components/Settings/ChatBotPages/TrainChatBot";
import PreviousFiles from "./Components/Settings/ChatBotPages/PreviousFiles";
import HierarchyPage from "./Components/Settings/HierarchyPage/HierarchyPage";
import CreateFormfromAPI from "./Components/Settings/APICustomForms/CreateFormfromAPI";
import CheckInDetailsInMap from "./Components/Settings/CheckInDetails/CheckInDetailsInMap";
import ProductFilter from "./Components/ProductFilter/ProductFilter";
import Forms from "./Components/Forms/Forms";
import Payment from "./Components/Connect/Payment";
import UserRefferals from "./Components/Connect/UserRefferals";
import Pivottable from "./Components/PivotTable/Pivottable";
import Commission from "./Components/Connect/Commission";
import PassBook from "./Components/PassBook/PassBook";
import PassbookHistory from "./Components/PassBook/PassbookHistory";
import ReferenceInfo from "./Components/PassBook/ReferenceInfo";
import WhiteLabeling from "./Components/WhiteLabeling/WhiteLabeling";
import ClaimHierarchyPage from "./Components/Settings/ClaimHierarchy/ClaimHierarchyPage";
import SignupSubscriberPage from "./Components/SignupSubscription/SignupSubscriberPage";
import LeaveHierarchyPage from "./Components/Settings/LeaveHierarchy/LeaveHierarchyPage";
import ReimbursementLimitsPage from "./Components/Settings/ReimbursementLimits/ReimbursementLimitsPage";
import Payroll from "./Components/Payroll.js/Payroll";
import Region from "./Components/Settings/Region/Region";
import OrthoAttendence from "./Components/OrhtoAttendence";
import TravelReportAttachments from "./Components/TravelReport/TravelReportAttachments";
import TravelReportAttachmentView from "./Components/TravelReport/TravelReportAttachmentView";
import TravelReportDataTable from "./Components/TravelReport/TravelReportDataTable";
import TravelReport from "./Components/TravelReport/TravelReport";
import Activities from "./Components/Settings/UserActivity/Activities";
import SignIn from "./SignIn";
import Signup from "./Components/PrivatePages/Signup";
import UpdatedForgotPasswordPage from "./Components/UpdatedForgotPasswordPage";
import RouteDeviationReport from "./Components/Routes/RouteDeviationReport";
import KPA from "./Components/Settings/KPA/KPA";
import ViewKpaTarget from "./Components/Settings/KPA/ViewKpaTarget";
import TravelManagement from "./Components/TravelManagement/TravelManagement";
import TravelRequest from "./Components/TravelManagement/TravelRequest/TravelRequest";
import TravelApproval from "./Components/TravelManagement/TravelApproval/TravelApproval";
import TravelViewNApprove from "./Components/TravelManagement/TravelApproval/TravelViewNApprove";
import TravelDesk from "./Components/TravelManagement/TravelDesk/TravelDesk";
import TravelDetails from "./Components/TravelManagement/TravelDesk/TravelDetails";
import TravelPayments from "./Components/TravelManagement/TravelPayments/TravelPayments";
import ExpensesLimitSettings from "./Components/Settings/ReimbursementLimits/ExpensesLimitSettings";
import ExpensesCategory from "./Components/Settings/ExpensesCategory/ExpensesCategory";
import Meetingdetails from "./Components/Meetings/Meetingdetails";

function App() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const loginId = sessionStorage.getItem("loginId");

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let activities = [];

    if (resPassData) {
      resPassData?.subscription?.modules.forEach((item) => {
        activities.push({ name: item.moduleName });
      });
    }
    setMenuItems(activities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // chatboot open modal
  const [liveChatModal, setLiveChatModal] = useState("");
  const openChatBoot = () => {
    setLiveChatModal(true);
  };

  const closeChatBoot = () => {
    setLiveChatModal(false);
  };
  // chatboot open modal

  return (
    <div className="App">
      {loginId && (
        <>
          <LiveChatBoot
            liveChatModal={liveChatModal}
            closeChatBoot={closeChatBoot}
          />
          {!liveChatModal && (
            <div className="live-chat-div" onClick={openChatBoot}>
              <div className="message-box">
                <span className="anim anim-1"></span>
                <span className="anim anim-2"></span>
                <span className="anim anim-3"></span>
              </div>
            </div>
          )}
        </>
      )}
      <SessionTimeout />
      <>
        {loginId ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Dashboard />} />
            <Route path="/forgotpassword" element={<Dashboard />} />
            <Route path="/resetpassword" element={<Dashboard />} />
            <Route path="/userTracker" element={<UserTrackerPage />} />
            <Route path="/userTracker/:id" element={<UserTrackerPage />} />
            <Route path="/textForm" element={<TextForm />} />
            <Route
              path="/settings/superadminpage"
              element={<SuperAdminPage />}
            />
            <Route path="/refferal" element={<Refferal />} />
            <Route path="/googlecredits" element={<GoogleCredits />} />
            <Route path="/apicredits" element={<ApiCredits />} />
            <Route path="/apicredits/:key" element={<ApiCredits />} />

            <Route exact path="/settings/leavetype" element={<LeaveType />} />
            <Route exact path="/claimsummary" element={<ClaimSummary />} />
            <Route exact path="/createcustomforms" element={<CustomForm />} />
            <Route exact path="/forms" element={<Forms />} />
            <Route exact path="/mapbycategory" element={<MapByCategory />} />
            <Route
              exact
              path="/settings/viewonmap"
              element={<CheckInDetailsInMap />}
            />
            <Route exact path="/dynamic/reports" element={<Pivottable />} />
            <>
              <Route exact path="/passbook/:gfrefId" element={<PassBook />} />
              <Route
                exact
                path="/passbookHistory"
                element={<PassbookHistory />}
              />
            </>
            <Route exact path="/referenceInfo" element={<ReferenceInfo />} />
            <Route exact path="/whiteLabeling" element={<WhiteLabeling />} />
            <Route exact path="/payroll" element={<Payroll />} />
            <Route exact path="/region" element={<Region />} />
            <Route
              exact
              path="/orthoAttendence"
              element={<OrthoAttendence />}
            />
            <Route exact path="/travelReport" element={<TravelReport />} />
            <Route
              exact
              path="/travelReportAttachment/:clientId"
              element={<TravelReportAttachments />}
            />
            <Route
              exact
              path="/travelReportAttachmentView"
              element={<TravelReportAttachmentView />}
            />
            <Route
              exact
              path="/travelReportTableData/:clientId"
              element={<TravelReportDataTable />}
            />
            <Route
              exact
              path="/routeDeviation"
              element={<RouteDeviationReport />}
            />

            <>
              {resPassData ? (
                <>
                  {menuItems.length !== 0 &&
                    menuItems?.map((items, i) => (
                      <React.Fragment key={i}>
                        {items.name === "Dashboard" && (
                          <Route
                            exact
                            path="/dashboard"
                            element={<Dashboard />}
                          />
                        )}
                        <Route exact path="/tracker" element={<Tracker />} />
                        <Route
                          exact
                          path="/trackbyuser/:id"
                          element={<TrackByUser />}
                        />
                        <Route
                          exact
                          path="/trackbyuser/:id/:checkout"
                          element={<TrackByUser />}
                        />
                        {items.name === "Users" && (
                          <Route exact path="/HR/Users" element={<Users />} />
                        )}
                        {items.name === "Travel Desk" && (
                          <>
                            <Route
                              exact
                              path="/travelmanagement"
                              element={<TravelManagement />}
                            />
                            <Route
                              exact
                              path="/travelmanagement/travelrequest"
                              element={<TravelRequest />}
                            />
                            <Route
                              exact
                              path="/travelmanagement/travelrequest/:id"
                              element={<TravelRequest />}
                            />
                            <Route
                              exact
                              path="/travelmanagement/travelapproval"
                              element={<TravelApproval />}
                            />

                            <Route
                              exact
                              path="/travelmanagement/travelviewnapprove"
                              element={<TravelViewNApprove />}
                            />

                            <Route
                              exact
                              path="/travelmanagement/traveldesk"
                              element={<TravelDesk />}
                            />

                            <Route
                              exact
                              path="/travelmanagement/travelticketdetails"
                              element={<TravelDetails />}
                            />

                            <Route
                              exact
                              path="/travelmanagement/travelpayments"
                              element={<TravelPayments />}
                            />

                            <Route
                              exact
                              path="/settings/expensesslimit"
                              element={<ExpensesLimitSettings />}
                            />
                            <Route
                              exact
                              path="/settings/expensesCategory"
                              element={<ExpensesCategory />}
                            />
                          </>
                        )}
                        <Route
                          exact
                          path="/viewsubordinates"
                          element={<ViewSubordinates />}
                        />
                        {items.name === "Monthly" && (
                          <Route
                            exact
                            path="/HR/Monthly"
                            element={<AttendanceMonthly />}
                          />
                        )}
                        {items.name === "Fullday" && (
                          <>
                            <Route
                              exact
                              path="/HR/Fullday"
                              element={<AttendanceFullday />}
                            />
                            <Route
                              exact
                              path="/HR/Fullday/:usrId"
                              element={<AttendanceFullday />}
                            />
                          </>
                        )}
                        {items.name === "Client Complaint" && (
                          <Route
                            exact
                            path="/complaints"
                            element={<Complaints />}
                          />
                        )}
                        {items.name === "Leaves" && (
                          <Route exact path="/HR/Leaves" element={<Leaves />} />
                        )}
                        {items.name === "Orders" && (
                          <>
                            <Route exact path="/orders" element={<Orders />} />
                            <Route
                              exact
                              path="/orderDetails/:orderId"
                              element={<OrderDetails />}
                            />
                          </>
                        )}
                        {items.name === "Meetings" && (
                          <>
                            <Route
                              exact
                              path="/meetings"
                              element={<Meetings />}
                            />
                            <Route
                              exact
                              path="/meetings/:usrId"
                              element={<Meetings />}
                            />
                          </>
                        )}
                        {items.name === "Clients" && (
                          <>
                            <Route
                              exact
                              path="/company"
                              element={<Clients />}
                            />
                            <Route
                              exact
                              path="/company/:id"
                              element={<Clients />}
                            />
                          </>
                        )}
                        {items.name === "Products" && (
                          <>
                            <Route
                              exact
                              path="/products"
                              element={<Products />}
                            />
                            <Route
                              exact
                              path="/products/:prodId"
                              element={<ProductsViewPage />}
                            />
                          </>
                        )}
                        {items.name === "Products" && (
                          <Route
                            exact
                            path="/Cartpage"
                            element={<CartPage />}
                          />
                        )}
                        {items.name === "Spares" && (
                          <Route exact path="/spares" element={<Spares />} />
                        )}
                        {items.name === "Payment" && (
                          <Route exact path="/payment" element={<Payment />} />
                        )}
                        {items.name === "Ref User" && (
                          <Route
                            exact
                            path="/user/referrals"
                            element={<UserRefferals />}
                          />
                        )}
                        {items.name === "Ref User" && (
                          <Route
                            exact
                            path="/commission"
                            element={<Commission />}
                          />
                        )}
                        {items.name === "Settings" && (
                          <Route
                            exact
                            path="/settings"
                            element={<SettingPage />}
                          />
                        )}
                        {items.name === "Settings" && (
                          <Route exact path="/settings/KPA" element={<KPA />} />
                        )}
                        {items.name === "Settings" && (
                          <Route
                            exact
                            path="/viewKpaTarget/:kpaId"
                            element={<ViewKpaTarget />}
                          />
                        )}
                        {items.name === "Routes" && (
                          <Route path="/routes" element={<RoutePlan />} />
                        )}
                        {items.name === "Product Price" && (
                          <Route
                            exact
                            path="/settings/ProductPrice"
                            element={<ProductFilter />}
                          />
                        )}
                        {/* {items.name === "Amc" && */}
                        <Route exact path="/amc" element={<Amc />} />
                        <Route
                          exact
                          path="/pms/projects"
                          element={<Projects />}
                        />
                        <Route exact path="/pms/phase" element={<Phase />} />
                        <Route exact path="/pms/tasks" element={<Tasks />} />
                        <Route
                          exact
                          path="/pms/reports"
                          element={<Reports />}
                        />
                        <Route
                          exact
                          path="/pms/paymentdetails"
                          element={<PaymentDetails />}
                        />
                        {/* } */}
                        {items.name === "Local Claims" && (
                          <Route
                            exact
                            path="/localclaim"
                            element={<LocalClaim />}
                          />
                        )}
                        {/* <Route exact path="/claims/manager" element={<ClaimsManager />} />
              <Route exact path="/claims/admin" element={<ClaimsAdmin />} /> */}
                        {items.name === "Outstation Claims" && (
                          <Route
                            exact
                            path="/outstationclaim"
                            element={<OutStationClaim />}
                          />
                        )}
                        <Route
                          exact
                          path="/meetingsmodel"
                          element={<MeetingDetailsModel />}
                        />
                        {items.name === "Schedule" && (
                          <Route
                            exact
                            path="/settings/schedule"
                            element={<Schedule />}
                          />
                        )}
                        {items.name === "Track Clients" && (
                          <Route
                            exact
                            path="/settings/trackclients"
                            element={<TrackClients />}
                          />
                        )}
                        {items.name === "Department" && (
                          <Route
                            exact
                            path="/settings/department"
                            element={<Department />}
                          />
                        )}
                        {items.name === "Designation" && (
                          <Route
                            exact
                            path="/settings/designation"
                            element={<Designation />}
                          />
                        )}
                        {items.name === "Designation" && (
                          <Route
                            exact
                            path="/settings/claimhierarchy"
                            element={<ClaimHierarchyPage />}
                          />
                        )}
                        {items.name === "Designation" && (
                          <Route
                            exact
                            path="/settings/leavehierarchy"
                            element={<LeaveHierarchyPage />}
                          />
                        )}
                        {items.name === "Meeting Purpose" && (
                          <Route
                            exact
                            path="/settings/meetingpurpose"
                            element={<MeetingPurpose />}
                          />
                        )}
                        {items.name === "Basefare" && (
                          <Route
                            exact
                            path="/settings/basefare"
                            element={<BaseFare />}
                          />
                        )}
                        {items.name === "Reimbursement Claim" && (
                          <Route
                            exact
                            path="/settings/reimbursementClaim"
                            element={<ReimbursementLimitsPage />}
                          />
                        )}

                        {items.name === "Profile" && (
                          <Route
                            exact
                            path="/settings/profile"
                            element={<Profile />}
                          />
                        )}
                        {items.name === "UserActivity" && (
                          <Route
                            path="/settings/UserActivity"
                            element={<Activities />}
                          />
                        )}

                        {items.name === "Account Settings" && (
                          <Route
                            exact
                            path="/settings/accountsettings"
                            element={<AccountSettings />}
                          />
                        )}
                        {items.name === "Account Details" && (
                          <Route
                            exact
                            path="/settings/accountdetails"
                            element={<AccountDetails />}
                          />
                        )}
                        {items.name === "Custom Forms" && (
                          <Route
                            exact
                            path="/settings/Customforms"
                            element={<CreateFormfromAPI />}
                          />
                        )}

                        {/* <Route
                          exact
                          path="/freetier"
                          element={<CreateSubscription />}
                        /> */}
                        {/* <Route
                          exact
                          path="/Trial"
                          element={<CreateSubscription />}
                        /> */}

                        <Route exact path="/Trial" element={<Signup />} />

                        {items.name === "Device Details" && (
                          <Route
                            exact
                            path="/settings/devicedetails"
                            element={<DeviceDetails />}
                          />
                        )}
                        {items.name === "Client Category" && (
                          <Route
                            path="/settings/clientCategory"
                            element={<ClientCategory />}
                          />
                        )}
                        {items.name === "Client Stage" && (
                          <Route
                            path="/settings/clientStage"
                            element={<ClientStage />}
                          />
                        )}
                        {items.name === "Custom Field" && (
                          <Route
                            path="/settings/customField"
                            element={<CreateCustomFields />}
                          />
                        )}
                        {items.name === "Holiday" && (
                          <Route
                            path="/settings/Holiday"
                            element={<Holiday />}
                          />
                        )}
                        {items.name === "Hierarchy" && (
                          <Route
                            path="/settings/Hierarchy"
                            element={<HierarchyPage />}
                          />
                        )}
                        {items.name === "Product Price" && (
                          <Route
                            path="/settings/ProductPrice"
                            element={<ProductFilter />}
                          />
                        )}
                        {items.name === "Shift" && (
                          <Route path="/settings/Shift" element={<Shift />} />
                        )}
                        <Route
                          exact
                          path="/kmrefresh/meetings"
                          element={<KilometerRefreshPage />}
                        />
                        <Route
                          exact
                          path="/kmrefresh/attendance"
                          element={<KilometerRefreshAttendance />}
                        />
                        <Route
                          exact
                          path="/allsubscriptiondetails"
                          element={<SubscriptionDetails />}
                        />
                        <Route
                          exact
                          path="/adddropdowndata"
                          element={<AddDropdownData />}
                        />
                        <Route
                          exact
                          path="/googleapikeylist"
                          element={<GoogleAPIHitCount />}
                        />
                        <Route
                          exact
                          path="/googleapikeylist/:key"
                          element={<GoogleAPIHitCount />}
                        />
                        <Route
                          exact
                          path="/exceptionhandler"
                          element={<ExceptionHandler />}
                        />
                        <Route
                          exact
                          path="/subscriptionmodule"
                          element={<MeetingManagement />}
                        />
                        <Route
                          exact
                          path="/settings/roles"
                          element={<Role />}
                        />
                        <Route
                          exact
                          path="/settings/ProductCategory"
                          element={<ProductCategory />}
                        />
                        <Route
                          exact
                          path="/settings/TrainChatBot"
                          element={<TrainChatBot />}
                        />
                        <Route
                          exact
                          path="/settings/pdfFiles"
                          element={<PreviousFiles />}
                        />
                      </React.Fragment>
                    ))}
                  <>
                    {resPassData &&
                    resPassData?.desingation?.role?.roleName === "Admin" &&
                    resPassData.subscriptionId === "SUB0000000001" &&
                    resPassData.subscriptionId !== "" ? (
                      // access
                      <>
                        <Route
                          path="/settings/superadminpage"
                          element={<SuperAdminPage />}
                        />
                        <Route
                          path="/googlecredits"
                          element={<GoogleCredits />}
                        />
                        <Route path="/exception" element={<ExceptionPage />} />
                        <Route
                          path="/mobileException"
                          element={<MobileException />}
                        />
                        <Route path="/apicredits" element={<ApiCredits />} />
                        <Route
                          path="/apicredits/:key"
                          element={<ApiCredits />}
                        />
                        <Route
                          exact
                          path="/subscription"
                          element={<SuscriberPage />}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          exact
                          path="/settings/useractivities"
                          element={<PageNotFound />}
                        />
                        <Route
                          path="/settings/superadminpage"
                          element={<PageNotFound />}
                        />
                        <Route
                          path="/googlecredits"
                          element={<PageNotFound />}
                        />
                        <Route path="/exception" element={<PageNotFound />} />
                        <Route
                          path="/mobileException"
                          element={<PageNotFound />}
                        />
                        <Route path="/apicredits" element={<PageNotFound />} />
                        <Route
                          path="/apicredits/:key"
                          element={<PageNotFound />}
                        />
                      </>
                    )}
                    <Route exact path="*" element={<UnAutherized />} />
                  </>
                </>
              ) : (
                <>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="*" element={<PageNotFound />} />
                </>
              )}
            </>
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/barrix" element={<SignIn />} />
            <Route path="*" element={<SignIn />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route
              exact
              path="/updatedforgotPassword"
              element={<UpdatedForgotPasswordPage />}
            />
            <Route
              path="/barixforgotpassword"
              element={<UpdatedForgotPasswordPage />}
            />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route
              exact
              path="/subscriptionexpiry"
              element={<CheckSubscription />}
            />
            {/* <Route exact path="/freetier" element={<CreateSubscription />} /> */}
            {/* <Route exact path="/Trial" element={<CreateSubscription />} /> */}
            {/* <Route exact path="/Trial/BDE" element={<CreateSubscription />} /> */}
            <Route
              exact
              path="/signupSubscription"
              element={<SignupSubscriberPage />}
            />
          </Routes>
        )}
      </>
    </div>
  );
}

export default App;
