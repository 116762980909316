import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Leavetype.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import Select from "react-select";
import { optionsStatusList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import AddLeaveType from "./AddLeaveType";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { useNavigate } from "react-router-dom";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import Footer from "../../Footer";
import { travelizeAuth } from "../../HeaderAuthentication";
import DownloadLoading from "../../ReUseComponents/DownloadLoading";

export default function LeaveType() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [editLeaveType, setEditLeaveType] = useState(false);
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [editLeaveTypeID, setEditLeaveTypeID] = useState(null);
  const [activeStatus, setActiveStatus] = useState(2);
  const [leaveTypesData, setLeaveTypesData] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditLeaveType(false);
    setEditLeaveTypeID(null);
  };

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/LeaveType/BindLeaveTypeDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveTypes(
            res.result.map((data) => {
              return { value: data.leaveTypeID, label: data.leaveTypeName };
            })
          );
        } else {
          setLeaveTypes([]);
        }
      });
  };

  //  LEAVE TYPES TABLE START

  useEffect(() => {
    fetchLeaveTypesData();
  }, [leaveTypeID, activeStatus, PageSize, currentPage]);

  const fetchLeaveTypesData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/LeaveType/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${activeStatus}&LeaveTypeID=${leaveTypeID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveTypesData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setLeaveTypesData("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  //  LEAVE TYPES TABLE END

  const clearSerachBySelect = () => {
    setLeaveTypeID(0);
    setActiveStatus(2);
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={4} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">LEAVE TYPE</h6>
                      </div>
                    </Col>
                    <Col md={8} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn
                            onClickEffect={(e) => {
                              setShow(!show);
                              setEditLeaveTypeID(null);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div className="filter-row-input-boxes">
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={leaveTypes}
                            placeholder="Select Leave Type"
                            value={leaveTypeID === 0 ? "" : leaveTypes.value}
                            onInputChange={leaveTypes.label}
                            onChange={(data) => setLeaveTypeID(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <div>
                          <Select
                            className="react-select-container-list"
                            classNamePrefix="react-select-list"
                            options={optionsStatusList}
                            placeholder="Select Status"
                            value={
                              activeStatus === 2 ? "" : optionsStatusList.value
                            }
                            onInputChange={optionsStatusList.label}
                            onChange={(data) => setActiveStatus(data.value)}
                            isSearchable={true}
                          />
                        </div>
                        <MiClearButton
                          onClickEffect={clearSerachBySelect}
                          btnName="Clear"
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="leavetype-table">
                  <div
                    className="table-responsive leavetype-scroll"
                    onScroll={(e) =>
                      handleScroll(
                        e,
                        leaveTypesData,
                        totalData,
                        setLoading,
                        setPageSize,
                        PageSize
                      )
                    }
                  >
                    <table className="table leavetype_table_body">
                      <thead className="thead-light leavetype-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>NAME</th>
                            <th>SHORT NAME</th>
                            <th>MAX NO.OF DAYS</th>
                            <th>DESCRIPTION</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : leaveTypesData !== "" ? (
                          leaveTypesData.map((data, i) => (
                            <tr className="leavetype-row-data" key={i}>
                              <td>
                                <div className="table-row-head-Text">
                                  {data?.leaveTypeName}
                                </div>
                              </td>
                              <td>{data?.leaveTypeSortName}</td>
                              <td>{data?.maxNoOfDays}</td>
                              <td style={{ maxWidth: "250px" }}>
                                {data?.description}
                              </td>
                              <td>
                                {data?.status == 1 ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setShow(!show);
                                    setEditLeaveTypeID(data?.leaveTypeID);
                                  }}
                                  className="btn leavetype-edit-link"
                                >
                                  <i className="fa fa-edit" /> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5}>----- NO DATA FOUND -----</td>
                          </tr>
                        )}
                        {loading && <OnScrollLoading />}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <SbDownloadBtn
                  // onClickEffect={exportAllMeetings}
                  />
                </div>

                {/* ADD PURPOSE MODEL START */}
                <AddLeaveType
                  show={show}
                  handleClose={handleClose}
                  fetchLeaveTypesData={fetchLeaveTypesData}
                  editLeaveTypeID={editLeaveTypeID}
                />
                {/* ADD PURPOSE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
          <Footer />
          {/* {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )} */}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
